.glyphicon-eye-open{
    background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_eye.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 14px;
    height: 12px;
}
.icon{
    &__suscription{
        width: 15px;
        height: 15px;
        margin-left: 8px;
    }
}