.motivos-subsanacion {
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  @media only screen and (max-width: 768px) {
    display: block;
  }

  #block-formularioexpuestomotivos-de-subsanacion-busquedapage-1 {
    grid-column: 1/2;
    grid-row: 1/2;
    margin-bottom: 48px;
    margin-right: 24px;

    @media only screen and (max-width: 768px) {
      margin-right: 0px;
    }

    input.form-submit {
      align-items: center;
      height: 48px;
      border: 2px solid #f3f3f3;
      font-size: 13px;
      letter-spacing: 0.8px;
      line-height: 16px;
      text-transform: uppercase;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      background: #f1315b;
      color: #fff;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .dropdown-subsanacion {
    grid-column: 1/2;
    grid-row: 2/3;
    margin-right: 24px;
    @media only screen and (max-width: 768px) {
      margin-right: 0px;
    }

    .facets-widget-links {
      > ul {
        padding-left: 0;
      }
      li.facet-item--expanded {
        margin-bottom: 32px;
        border-bottom: solid 1px #dcdcdc;
        padding-right: 24px;
        padding-bottom: 8px;
        position: relative;
        > a {
          pointer-events: none;
          
          &::after {
            position: absolute;
            right: 0;
            top: 4px;
            display: inline-block;
            content: "";
            flex: none;
            margin: auto;
            width: 20px;
            height: 20px;
            background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_arrow-next.svg);
            background-repeat: no-repeat;
            transform: rotate(90deg);
          }

          span {
            margin-bottom: 8px;
            margin-top: 8px;
            color: #8b9496;
            font-size: 14px;
            letter-spacing: 0.8px;
            text-transform: uppercase;
            text-decoration: none;
            cursor: pointer;
          }
        }

        &.custom-facet-li-expanded {
          > a {
            &::after {
              transform: rotate(270deg);
            }
          }
        }
        cursor: pointer;
      
        ul {
          display: none;
          padding-left: 0;
          margin-top: 24px;

          li {
            margin-bottom: 24px;
            margin-left: 16px;
            &:hover {
              background: #f3f3f3;
              width: fit-content;
              border-bottom: solid 2px #d80f3b;
            }

            a {
              color: #8b9496;

            }
          }
        }
      }
    }
  }
  // form {
  //   grid-column: 1;
  //   margin-right: 48px;
  //   fieldset {
  //     legend {
  //       display: none;
  //     }

  //     .fieldset-wrapper {
  //       .bef-nested {
  //         > ul {

  //           input.form-radio {
  //             display: none;
  //           }
  //           > li.active{
  //             > div.form-item-tid {
  //               &:after {
  //                 transform: rotate(270deg);
  //               }
  //             }
  //             ul {
  //               display: contents;

  //             }
  //           }
  //           > li {
  //             position: relative;
  //             margin-bottom: 24px;
  //             border-bottom: solid 1px #dcdcdc;

  //             > div.form-item-tid {
  //               display: flex;
  //               pointer-events: none;
  //               &:after {
  //                 content: "";
  //                 flex: none;
  //                 margin: auto;
  //                 width: 20px;
  //                 height: 20px;
  //                 background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_arrow-next.svg);
  //                 background-repeat: no-repeat;
  //                 transform: rotate(90deg);
  //               }
  //             }

  //             label {
  //               margin-bottom: 8px;
  //               margin-top: 8px;
  //               color: #8b9496;
  //               font-size: 14px;
  //               letter-spacing: 0.8px;
  //               text-transform: uppercase;
  //               text-decoration: none;
  //               cursor: pointer;
  //             }

  //             ul {
  //               display: none;
  //               position: absolute;
  //               background-color: #f9f9f9;
  //               min-width: 160px;
  //               box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  //               padding: 12px 16px;
  //               z-index: 1;

  //               li {
  //                 // @extend .dropdown-item;
  //                 transition: 0.3s;
  //                 margin-left: 20px;

  //                 &:hover {
  //                   background: #f3f3f3;
  //                   width: fit-content;

  //                   label {
  //                     font-weight: 700;
  //                     color: #1a2e35;
  //                     padding-right: 20px;
  //                     border-bottom: solid 2px #d80f3b;
  //                     margin: 20px;
  //                     padding-bottom: 10px;
  //                   }
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }

  //   .js-form-type-entity-autocomplete,
  //   .form-actions.form-group {
  //     margin-left: 44px;
  //   }

  //   .form-actions.form-group {
  //     input {
  //       align-items: center;
  //       height: 48px;
  //       border: 2px solid #f3f3f3;
  //       font-size: 13px;
  //       letter-spacing: 0.8px;
  //       line-height: 16px;
  //       text-transform: uppercase;
  //       -webkit-box-pack: center;
  //       -ms-flex-pack: center;
  //       justify-content: center;
  //       background: #f1315b;
  //       color: #fff;

  //       &:hover {
  //         opacity: 0.5;
  //       }
  //     }
  //   }
  // }

  .views-element-container {
    grid-column: 2/4;
    grid-row: 1/5;

    header{
      @extend h1;
    }
    @media only screen and (max-width: 768px) {
      margin-right: 48px;
      margin-left: 48px;
      margin-top: 48px;
    }
    .views-accordion-header.ui-state-active {
      background: #f1315b;
      border: none;
    }
  }
}
