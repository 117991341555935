.tag{
	&__open{
		font-family: $font-family-roboto;
		font-size: 12px;
		color: $color-link-secundary;
	}
	&__close{
		font-family: $font-family-roboto;
		font-size: 12px;
		color: $color-primary;
	}
	&__date{
		font-family: $font-family-roboto-black;
		font-size: 13px;
		color: $color-text-secundary;
	}
}