.licitation{
	position: relative;
	&__container{
		width: 100%;
	}
  	&__background-title{
		color: rgba(243,243,243,0.72);
		font-family: $font-family-roboto-black;
		font-size: 100px;
		font-weight: 900;
		letter-spacing: 12px;
		line-height: 104px;
	}
	&__title{
		color: $color-text-primary;
		font-family: $font-family-titles;
		font-size: 40px;
		// font-weight: 500;
		letter-spacing: 0;
		line-height: 48px;
	}
	&__subtitle{
		color: $color-text-primary;
		font-family: $font-family-subtitles;
		font-size: 24px;
		// font-weight: 500;
		letter-spacing: 0;
		line-height: 32px;
		margin-bottom: 32px;
	}
	&__principal-panel{
		display: flex;
	}
	&__list-card-notification{
		display: flex;
		flex-direction: column;
	}
  &__table{
    td:first-child{
      text-align: start;
    }
  }
	// &__table{
	// 	th,td{
	// 		text-align: start;
	// 	}
	// 	&__time-limit-table{
	// 		th{
	// 			text-transform: uppercase;
	// 			&:nth-child(2), &:first-child{
	// 				text-align: start;
	// 			}
	// 			&:last-child{
	// 				text-align: end;
	// 			}
	// 			a{
	// 				color: #FFFFFF;
	// 				letter-spacing: 0.8px;
	// 				line-height: 24px;
	// 				text-align: left;
	// 				font-size: 12px;
	// 			}
	// 		}
	// 		td:first-child{
	// 			text-align: start;
	// 			a{
	// 				color: #0F6ED8;
	// 				font-size: 12px;
	// 				letter-spacing: 1px;
	// 				line-height: 24px;
	// 			}
	// 		}
	// 		td:nth-child(2){
	// 			text-align: start;
	// 			color: #8B9496;
	// 			font-family: $font-family-roboto-medium;
	// 			font-size: 20px;
	// 			font-weight: 500;
	// 			letter-spacing: 1px;
	// 			line-height: 24px;
	// 			text-decoration: none;
	// 			& a{
	// 				color: #8B9496;
	// 				font-family: $font-family-roboto-medium;
	// 				font-size: 20px;
	// 				font-weight: 500;
	// 				letter-spacing: 1px;
	// 				line-height: 24px;
	// 				text-decoration: none;
	// 				&:hover{
	// 					text-decoration: none;
	// 				}
	// 			}
	// 		}
	// 		td:last-child{
	// 			text-align: end;
	// 			color: #2C5660;
	// 			font-size: 13px;
	// 			letter-spacing: 1px;
	// 			line-height: 16px;
	// 		}
	// 		th, td {
	// 			min-width: 200px;
	// 		}
	// 	}
	// }
	&__image{
		position: absolute;
		right: 0;
		padding-top: 105px;
	}
}

@media only screen and (max-width: 1280px){
	.licitation{
		&__image{
			width: 48%;
			display: block;
		}
	}
}

@media only screen and (max-width: 992px){
	.licitation{
		margin-bottom: 40px;
		&__list-card-notification {
			flex-direction: row;
		}
		&__image{
			position: relative;
			padding-top: 60px;
			width: 70%;
			margin-bottom: 32px;
			margin-right: auto;
			margin-left: auto;
			display: none;
		}
	}
}

@media only screen and (max-width: 768px){
	.licitation{
		&__list-card-notification{
			flex-direction: column;
		}
		&__image {
			width: 80%;
		}
	}	
}