.other-process{
  position: relative;
  // .form-group{
  //   min-height: 200px;
  // }
  &__detail-envolture{
    display: flex;
    padding-top: 32px;
    .field--name-simplenews-issue {
      display: none;
    }
    .limit__code div {
        display: block;
    }
    .field--name-field-titulo-corto-convoca {
      display: none;
    }
  }
  &__detail-content{
    width: 100%;
    padding: 0px 300px 0px 100px;
    margin-bottom: 32px;
    text-align: justify;
    position: relative;
    // & div:nth-child(4){
    //   display: none;
    // }
  }
  &__other-process{

  }
  &__background{
    padding-top: 150px;
    &--medium{
      padding-top: 27px;
    }
  }
  &__ontime{
    padding-top: 20px;
    margin-bottom: 64px;
  }
  &__closed{
    padding-top: 89px;
    margin-bottom: 64px;
  }
  &__container{
    display: flex;
    justify-content: space-between;
    padding-top: 32px;
    align-items: center;
  }
  &__title{
    color: $color-text-primary;
    font-family: $font-family-subtitles;
    font-size: 24px;
    // font-weight: 500;
    letter-spacing: 0;
    line-height: 32px;
    &--mg-top{
      padding-top: 47px;
    }
  }
  &__sidebar-container{
    display: flex;
    align-items: flex-start;
    margin-bottom: 64px;
  }
  .view{
    &-footer{
      text-align: center;
    }
  }
}

@media only screen and (max-width: 1280px){
  .other-process{
    &__closed{
      padding-top: 0;
    }
		&__container{
			flex-direction: column;
    }
    &__detail-content{
      padding: 0 40px;
    }
    .image{
      width: 60%;
    }
  }
}


@media only screen and (max-width: 768px){
  .other-process{
    margin-bottom: 0;
    &__filter{
      z-index: 5;
    }
    .image{
      width: 70%;
    }
    &__title{
      padding-top: 88px;
      margin-bottom: 0;
    }
    &__detail-envolture{
      flex-direction: column;
    }
    &__detail-content{
      padding: 0;
    }
  }
}

@media only screen and (max-width: 480px){
  .other-process{
    .image{
      width: 100%;
    }
  }
}


.content.container__body.other-process__detail-envolture {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media only screen and (max-width: 768px){
  .other-process__detail-envolture .limit__code div{
    margin-top: 60px;
  }
}
