.breadcrumb{
    height: 40px;
  background-color: #F9F9F9;
  padding-left: 30px;
      display: flex;
	  align-items: center;
	  margin-bottom: 0;
  	&__item, li{
      padding: 0 6px;
      color: $color-text-therciary;
      letter-spacing: 0.8px;
      line-height: 16px;
      text-transform: uppercase;
			font-size: 13px;
			display: flex;
			align-items: center;
			visibility: hidden;
			&:before {
						content: '';
						width: 3px;
						height: 3px;
						background-color: $color-text-therciary;
						border-radius: 50%;
						display: flex;
						margin-right: 12px;
						transform: none;
			}
			&:first-child{
						&:before {
							content: '';
							width: 15px;
							height: 15px;
							background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_house.svg);
							margin-right: 14px;
							border-radius: initial;
							background-repeat: no-repeat;
							background-size: 15px 15px;
							background-color: $color-text-sexty;
							padding-right: 17px;
						}
			}
	  }
	&__link,& li a{
		text-decoration: none;
		color: $color-text-therciary;
		cursor: pointer;
		font-family: $font-family-default;
		font-size: 13px;
		&:hover{
			font-family: $font-family-roboto-bold;
		}
	}
	&__cofirm{
		& li{
			&:nth-child(3), &:nth-child(4),  &:nth-child(6){
				display: none;
			}
		}
	}
}
@media only screen and (max-width: 768px){
	.breadcrumb{
		flex-wrap: wrap;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		padding: 8px 4px;
		height: auto;
		&__item, li, a{
			font-size: 11px;
		}
		&__link,& li a{
			font-size: 11px;
		}
	}
}