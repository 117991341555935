.asesor{
    &__form{
        & .form-inline{
            display: flex;
            height: 58px;
            margin-bottom: 30px;
            align-items: flex-end;
            & div:first-child{
                margin-right: 16px;
            }
        }
        & button, & button:hover{
            color: white;
            background-color: $color-buttons; 
        }
    }
    &__result-item{
        & h3 a{
            color: $color-primary-light;
        }
        & .views-row{
            padding-left: 16px;
            margin-bottom: 8px;
            border-top: 1px dotted #F1315B;
            padding-top: 8px;
            max-width: 400px;
            &:first-child{
                border-top: none;
            }
        }
    }
}