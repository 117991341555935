.limit{
  &__container{
		position: absolute;
    top: 5px;
		right: 0;
		font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
		color: $color-text-secundary;
		width: 100%;
    display: flex;
		justify-content: flex-end;
		padding: 5px 300px 0px 100px;
	}
	&__block{
		display: flex;
		flex-direction: column;
		text-align: end;
	}
	&__date-end{
		& .field--label{
			display: none;
		}
		& .field--item{
			text-transform: uppercase;
			letter-spacing: 2px;
			visibility: hidden;
		}
	}
	&__code{
		//height: 40px;
	}
	&__title{

	}
}

@media only screen and (max-width: 1280px){
	.limit{
		&__container{
			padding: 0 40px;
		}
	}
}

@media only screen and (min-width: 768px){
	.limit{
		&__code{
			width: 45%;
			word-break: break-word;
		}
	}
}

@media only screen and (max-width: 768px){
	.limit{
		&__container{
			padding: 0;
		}
	}
}

@media only screen and (max-width: 768px){
	.limit{
		&__code{
			padding-top: 70px;
		}
		&__block{
			text-align: start;
		}
		&__container{
			justify-content: start;
		}
	}
}