.card-notification{
	&__container{
		width: 478px;
		display: flex;
		padding: 20px;
		margin-bottom: 32px;
		&:hover{
			padding: 22px;
			// width: 520px;
			transition:.25s;
			border-radius: 8px;
			background: linear-gradient(135.63deg, #FFFFFF 0%, #FEFEFE 48.75%, #F9F9F9 100%);
			box-shadow: -2px -2px 2px 0 rgba(26,46,53,0.04), 2px 2px 2px 0 #FFFFFF, 4px 4px 16px 0 rgba(255,255,255,0.72), -4px -4px 16px 0 rgba(26,46,53,0.04);
			margin-top: -8px;
		}
	}

  &_button{//Este elemento es el que tiene _evento_ HOVER
    display:block;
    a{//Este elemento es el aplica el _efecto_ HOVER
      display:block;
      text-decoration: none;
      border-radius: 4px;
      transition:.25s;
      background:rgba(255,255,255,0);
    }
  }
  &_button:hover{
    a{
      box-shadow: 4px 4px 15px 0 rgba(26,46,53,0.24);
      transform: translate(-2px,-2px);
      background:rgba(255,255,255,1);
    }
  }

	&__container-video{
		padding-top: 80px;
		width: 50%;
		text-align: end;
		&--title{
			text-align: start;
    		padding-left: 100px;
		}
	}
	&__title{
		color: $color-text-therciary;
		font-family: $font-family-titles;
		font-size: 20px;
		// font-weight: 500;
		letter-spacing: 1px;
		line-height: 24px;
		margin-bottom: 6px;
    	display: block;
	}
	&__status{
	  color: $color-text-secundary;
		font-size: 13px;
		letter-spacing: 0;
		line-height: 16px;	
	}
	&__sumary{
		color: $color-text-secundary;
		font-size: 13px;
		letter-spacing: 0;
		line-height: 16px;
		margin: 0;
	}
	&__message{
		color: $color-text-primary;
		// font-size: 16px;
		letter-spacing: 0;
		line-height: 24px;
	}
	&__image{
		width: 63px;
		height: 63px;
		margin-right: 16px;
	}

//<div class="card-notification__button">
//  <a href="#">
//    <span class="card-notification__button-cuadro">
//      <span class="card-notification__button-icono">
//        <img src="/sites/sede/themes/drpl_sede/images/global/ic_pc-show.svg">
//      </span>
//      <span class="card-notification__button-texto">
//        <span class="card-notification__button--textoppal"></span>
//        <span class="card-notification__button--textosec"></span>
//      </span>
//    </span>
//  </a>
//  <span class="card-notification__button-nota">Nota: Lorem ipsum...</span>
//</div>


  &__button{//Este elemento es el que tiene _evento_ HOVER
    display:block;
    margin-bottom:14px;
    > a{//Este elemento es el aplica el _efecto_ HOVER
      display:block;
      text-decoration: none;
      border-radius: 4px;
      transition:.25s;
      background:rgba(255,255,255,0);
      padding:16px;
      margin-bottom:10px;
    }
  }
  &__button:hover{
    > a{
      box-shadow: 4px 4px 15px 0 rgba(26,46,53,0.24);
      transform: translate(-2px,-2px);
      background:rgba(255,255,255,1);
    }
  }

  //Estos elementos estructuran el boton. Usamos SPAN para evitar que ckEditor/Drupal modifiquen el enlace
  &__button{
    &-nota{
      display:block; //Nota visualmente fuera del efecto hover
    }
    &-cuadro{//Engloba icono y texto
      display:table;
    }
    &-icono,
    &-texto{
      display:table-cell;
      vertical-align:middle;
      text-align:left;
    }
    &-icono{
      padding-right: 20px;
      img{
        height: 64px;
        width: 64px;
      }
    }
    &--textoppal{
      display:block;
      color: #8B9496;
      font-family: "Roboto Medium", sans-serif;
      font-size: 20px;
      letter-spacing: 1px;
      line-height: 24px;
      text-decoration: none;
    }
    &--textosec{
      display:block;
      color: #2C5660;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 16px;
    }
  }
}



@media only screen and (max-width: 992px){
	.card-notification{
		&__container-video{
			width: 100%;
			padding-top: 0;
			margin-bottom: 32px;
		}
		&__container-video--title{
			padding-left: 0;
			margin-bottom: 20px;
		}
		&__container-video iframe{
			width: 100%;
			height: 527px;
		}
		&__container-video .field--name-field-media-oembed-video{ //.field--name-field-media-oembed-video{
			text-align: center;
		}
		&__container{
			width: 100%;
      &.half{
         width:100%;
         display:block;
       }
		}
	}
}

@media only screen and (max-width: 768px){
	.card-notification{
		&__container{
			width: 100%;
			margin-bottom: 16px;
		}
		&__container-video iframe {
			height: 260px;
		}
		&__sumary, &__status{
			font-size: 12px;
		}
		&__title{
			font-size: 17px;
		}
		&__image {
			width: 54px;
			height: 54px;
		}
	}
}