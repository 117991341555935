.search-general{
		&__envolture{
			display: flex;
			flex-direction: row-reverse;
			padding-top: 60px;
			margin-bottom: 62px;
			justify-content: space-between;
			// span.input-group-btn:after {
			// 	content: '';
			// 	height: 48px;
			// 	width: 48px;
			// 	border: 2px solid #F3F3F3;
			// 	border-radius: 100px 0 0 100px;
			// 	background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_filter.svg);
			// 	background-size: cover;
			// 	position: absolute;
			// 	-webkit-transform: translate(50px,-16px) scaleX(-1);
			// 	transform: translate(50px,-16px) scaleX(-1);
			// }
		}
		&__title{
			position: absolute;
			transform: translateY(-42px);
			left: 0;
		}
    	&__panel{
			// width: 550px;
			min-width: 273px;
			border-radius: 0 24px 24px 24px;
			background-color: $bg-body;
			// padding: 8px;
			box-shadow: 2px 2px 4px 0 rgba(26,46,53,0.04), -2px -2px 1px 0 #fff, -4px -4px 16px 0 rgba(255,255,255,0.88), 0 4px 16px 0 #dcdcdc;
			flex-wrap: wrap;
			padding: 8px;
			// padding-top: 50px;
			// padding-bottom: 24px;
			float: right;
			margin-left: 64px;
			height: max-content;
			position: relative;
			& .panel-default {
				border-color: transparent;
			}
			& div[id|='edit-advanced']{
				& .panel-heading{
					margin-bottom: 16px;
				}
				& .panel-body{
					padding: 0;
					& .action{
						text-align: center;
						margin-bottom: 16px;
					}
					& .criterion{
						& fieldset{
							padding: 0 15px;
						}
					}
				}
			}
			& .head__search-ico{
				width: 100%;
				& input[id|='edit-keys']{
					height: 40px;
					margin-top: 16px;
				}
			}
			& .input-group-btn{
				width: calc(100% - 42px);
			}
			& .glyphicon-search{
				content: '';
				background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_search-white.svg);
				height: 16px;
				width: 17px;
				background-size: cover;
				&:hover, &:active, &:focus{
					background: transparent;
				}
			}
		}
		#edit-keys{
			width:100%;
			margin-top:10px;
			margin-bottom:15px;
		}
		#edit-fecha1{
			font-size:13px;
		}
		#edit-fecha2{
			font-size:13px;

		}
		#edit-combine-1, #edit-combine-2{
			width:100%;
		}
		#edit-submit-buscar{
			width:100%;
		}
		#edit-actions{
			width: 100%;
			margin-bottom:10px;

		}
		.no-results h2{

    		width: 600px;
		}

		.form-item-keys, .form-item-combine-1, .form-item-combine-2{
			width:100%;
		}
		.form-inline .form-control{
			width:100%;
			margin-bottom:15px;
		}
		div#edit-secondary--content:before {
			content: 'FECHA DE CREACIÓN:';
			color: #2C5660;
			font-size: 13px;
			letter-spacing:1px;

		}
		.control-label{
			font-size:13px;
			letter-spacing:1px;
		}
		&__panel-content, & .search-general__panel div:first-child div .input-group{
			display: flex;
			flex-direction: column-reverse;
		}
		&__panel-button, & .search-general__panel div:first-child div .input-group .input-group-btn button{
			width: 100%;
			height: 48px;
			border: 2px solid #F3F3F3;
			color: #fff;
			font-size: 13px;
			letter-spacing: 0.8px;
			line-height: 16px;
			text-transform: uppercase;
			background: #F1315B;
			margin-right: 43px;
			&:before{
				content: "APLICAR FILTRO";
			}
			// &:after{
			// 	content: '';
			// 	height: 48px;
			// 	width: 48px;
			// 	border: 2px solid #F3F3F3;
			// 	border-radius: 100px 0 0 100px;
			// 	background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_filter.svg);
			// 	background-size: cover;
			// 	position: absolute;
			// 	transform: translate(50px, -16px) scaleX(-1);
			// }
			& span{
				display: none;
			}
		}
		&__panel-input & .search-general__panel div:first-child div .input-group .max-height{
			margin-top: 8px;
		}
		&__button{
			content: '';
			height: 48px;
			width: 48px;
			border: 2px solid #F3F3F3;
			border-radius: 100px 0 0 100px;
			background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_filter.svg);
			// background-size: cover;
			position: absolute;
			transform: scaleX(-1);
			background-repeat: no-repeat;
			background-size: 22px 18px;
			background-position: center;
			right: 5px;
			top: 8px;
			&:active, &:focus{
				background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_reset.svg);
				background-repeat: no-repeat;
				background-size: 22px 18px;
				background-position: center;
				& .filter-search{
				  display: none;
				}
			  }
		}

}


@media only screen and (max-width: 1280px){
		.search-general{
			&__panel{
				& .head__search-ico{
					width: 100%;
				}
			}
		}
	}

@media only screen and (max-width: 768px){
	.search-general{
		&__envolture{
			flex-direction: column;
		}
		&__panel{
			margin: 50px 0 40px 0;
		}
		&__title{
			// margin-left: 26px;
		}
		& ul, & ol{
			padding-left: 0;
			li {
				margin-bottom: 30px;
			}
		}
	}
}