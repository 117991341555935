a, .link{
    font-size: 16px;
    color: $color-link-secundary;
    font-family: $font-family-link;
	text-decoration: none;
	// word-break: break-all;
    cursor: pointer;
    &:hover, &:active, &:focus{
      text-decoration: none;
      color: $color-link-therciary;
	}
	&__secundary{
				text-decoration: none;
				color: $color-text-therciary;
				cursor: pointer;
				&:hover,  &:active, &:focus{
					font-family: $font-family-roboto-bold;
					color: $color-text-therciary;
				}
			
	}
	&__outline{
		color: $color-text-therciary;
		&:hover,  &:active, &:focus{
			font-family: $font-family-default;
			color: $color-text-therciary;
			text-decoration: none;
		}
	}
}

@media only screen and (max-width: 768px){
	a, .link{
		font-size: 13px;
	}
}
// a{
// 	font-size: 16px;
// 	color: $color-link-secundary;
// 	font-family: $font-family-link;
// 	text-decoration: none;
// 	cursor: pointer;
// 	&:hover, &:active, &:focus{
// 		text-decoration: none;
// 		color: $color-link-therciary;
// 	}
// }