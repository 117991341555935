.navbar-lateral{
  &__container{
		width: 514px;
		min-height: 552px;
		background-color: rgba(246,246,246,0.64);
		padding: 40px 72px;
		transform: translateY(-32px);
	}
	&__list-item, ul, ol{
		display: flex;
		flex-direction: column;
		&  li{
			padding-left: 22px;
		}
	}
	&__item{
		width: 220px;
		color: $color-text-therciary;
		font-size: 14px;
		letter-spacing: 0.8px;
		line-height: 16px;
		text-transform: uppercase;
		margin-bottom: 32px;
		text-decoration: none;
		cursor: pointer;
		&:last-child{
			margin-bottom: 0;
		}
		&:hover, &:focus, &:active{
			color: $color-text-primary;
			font-family: $font-family-roboto-bold;
			text-decoration: none;
		}
	}
	&__item-selected{
		width: 220px;
		color: $color-text-primary;
		font-family: $font-family-roboto-bold;
		font-size: 14px;
		letter-spacing: 0.8px;
		line-height: 16px;
		position: relative;
		display: block;
		&:before{
			content: "";
			height: 2px;
			width: 200px;
			// border-radius: 4px;
			background-color: $color-primary;
			display: block;
			position: absolute;
			bottom:-6px
    //   transform: translateX(0) translateY(50px);
		}
	}
	&__return{
		height: 48px;
		width: 209px;
		display: flex;
		margin-bottom: 40px;
	}
	&__return-ico{
		height: 48px;
		width: 48px;
		content: url('/sites/sede/themes/drpl_sede/src/images/general/ic_prev.svg');
		// border: 1px solid #DCDCDC;
	}
	&__return-text{
		height: 48px;
		width: 162px;
		border: 1px solid #DCDCDC;
		font-size: 13px;
		letter-spacing: 0.8px;
		line-height: 16px;
		text-align: center;
    	padding: 16px 0;
		color: $color-text-therciary;
		text-transform: uppercase;
		cursor: pointer;
	}
	&__link{
		text-decoration: none;
	}
	&__button-ico{
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: 'Roboto Bold', sans-serif;
		text-transform: uppercase;
		width: 180px;
		margin-bottom: 32px;
		margin-right: auto;
		margin-left: auto;
	}
	&__button{
		display: none;
		content: '';
		background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_menu.svg);
		background-repeat: no-repeat;
		background-size: 20px 20px;
		background-position-x: center;
		background-position-y: center;
		padding: 10px;
		width: 55px;
		height: 55px;
		background-color: rgba(246,246,246,1);
		border-radius: 50%;
		box-shadow: -2px -2px 2px 0 rgba(26,46,53,0.04), 2px 2px 2px 0 #fff, 4px 4px 14px 0 rgba(255,255,255,0.72), -4px -4px 14px 0 rgba(26,46,53,0.04);
		margin-bottom: 45px;
		margin-left: 16px;
		border: 1px solid #8b9496;
			&--open, &:active, &:focus{
				background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_close.svg);
			}
	}
	&__block{
		display: flex;
		flex-direction: column;
	}
}

@media only screen and (max-width: 1280px){
	.navbar-lateral{
		&__container{
			padding: 30px 20px;
			/* min-width: 290px; */
		}
		&__list-item{
			padding-left: 0;
		}
	}
}

@media only screen and (max-width: 1024px){
	.navbar-lateral{
		&__container{
			width: 490px;
			padding: 40px 42px;
			// margin: 130px auto 30px auto;
		}
	}
}

@media only screen and (max-width: 768px){
	.navbar-lateral{
		&__button{
			display: block;
		}
		&__container {
			display: none;
			width: auto;
			padding: 20px;
			background-color:rgba(246,246,246,1);
			min-height: auto;
			margin: 0px 15px;
			position: absolute;
			z-index: 99;
			margin-top: 100px;
			border-radius: 6px;
			border: 1px solid #8b9496;
		}
	}
}