.crumb-trail {
}

#block-views-block-convocatorias-y-ayudas-block-1
  .search-filter
  button[id|="edit-submit-convocatorias-y-ayudas"] {
  float: right;
  -webkit-transform: translateX(-46px);
  transform: translateX(-46px);
  text-align: end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  // -webkit-box-pack: end;
  // -ms-flex-pack: end;
  // justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 48px;
  border: 2px solid #f3f3f3;
  // color: #8b9496;
  font-size: 13px;
  letter-spacing: 0.8px;
  line-height: 16px;
  text-transform: uppercase;
  // background: #ffffff;
  width: 201px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #f1315b;
  color: #fff;
  margin-top: -45px;
}
.search-filter__head-filter,
.search-filter input[id|="edit-submit-convocatorias-y-ayudas"] {
  float: right;
  -webkit-transform: translateX(-46px);
  transform: translateX(-46px);
  text-align: end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  // -webkit-box-pack: end;
  // -ms-flex-pack: end;
  // justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 48px;
  border: 2px solid #f3f3f3;
  // color: #8b9496;
  font-size: 13px;
  letter-spacing: 0.8px;
  line-height: 16px;
  text-transform: uppercase;
  // background: #ffffff;
  width: 201px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #f1315b;
  color: #fff;
}

@media only screen and (max-width: 768px){
  
  #block-views-block-convocatorias-y-ayudas-block-1 .search-filter button[id|='edit-submit-convocatorias-y-ayudas']{
    float: left;
    margin-left: 45px;
    margin-top: 100px;
  }
  
  .block-views-blockconvocatorias-y-ayudas-block-1 .search-filter div div[id|='edit-secondary']{
    bottom: -148px;
  }
  
  .search-filter div div[id|='edit-secondary'] .panel-body{
    float: left;
    left: -212px;
    bottom: -260px;
  }
  
  .form-group .filter-menu{
    position: relative;
    z-index: 1;
  }
  
  .form-group table{
    position: relative;
    z-index: 0;
  }
}

@media only screen and (max-width: 768px){
  .region-content .tipografy__title{
    margin-bottom: 100px;
  }

  
  .block-views-blockconvocatorias-y-ayudas-block-4 .search-filter div div[id|='edit-secondary']{
    bottom: 0px;
  }
  
  .block-views-blockconvocatorias-y-ayudas-block-4 .filter-menu .form--inline div[data-drupal-selector|='edit-actions']{
    bottom: 0px;
  }
  /*.search-filter div div[id|='edit-secondary'] {*/
  /*  bottom: 0px;*/
  /*}*/
}

.search-filter__head-filter .glyphicon,
.search-filter input[id|="edit-submit-convocatorias-y-ayudas"] .glyphicon {
  display: none;
}

.main-container {
  max-width: 1400px;
}
.breadcrumb > li + li:before {
  content: "";
  padding: 0px;
  color: #ccc;
}

/* MIGRACION D9 */
.slick .slick__arrow button:before {
  font-size: 1.5rem;
}
.slick .slick__arrow button {
  background: #8b94a0;
  width: 25px;
  height: 25px;
  padding: 0;
}
#edit-field-fecha-fin-plazo-value--wrapper input[type="radio"] {
  top: -2px;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  background-color: #ffff;
}
#edit-field-fecha-fin-plazo-value--wrapper
  input[type="radio"]:checked
  + label:after {
  content: "";
  width: 39px;
  position: absolute;
  height: 3px;
  background: #0fd8ac;
  display: block;
  bottom: 6px;
  left: 50%;
  transform: translateX(calc(-50% - 10px));
}
.js-form-item-field-fecha-fin-plazo-value .option {
  padding-bottom: 15px;
  cursor: pointer;
}
.newsletter .form-email {
  width: 582px;
  border: 1px solid #ccc;
  color: #333333;
}
#sede-newsletter-form .js-form-type-radio input[type="radio"],
#sede-newsletter-form .js-form-type-radio-inline input[type="radio"] {
  all: revert;
}
.newsletter .form-item-email {
  padding-bottom: 15px;
}
#sede-newsletter-form .js-form-type-radio label {
  padding-left: 0;
}
.js-form-type-radio label,
.checkbox label {
  min-height: 22px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}
.newsletter #edit-options {
  padding-top: 10px;
}
.highlighted div div {
  background-color: #ecfcf8;
  margin: 16px 0;
  padding: 16px;
}

/* AUB */
.boton-subcriber {
  width: 105px;
}
.search-filter div div[id|="edit-secondary"] div a:focus {
  color: transparent;
}
table,
.table {
  margin-bottom: 20px;
}
#views-exposed-form-convocatorias-y-ayudas-block-1 .form-actions .btn,
#views-exposed-form-convocatorias-y-ayudas-block-4 .form-actions .btn {
  margin-right: 0px;
}
#views-exposed-form-convocatorias-y-ayudas-block-1 .panel-title,
#views-exposed-form-convocatorias-y-ayudas-block-4 .panel-title {
  margin: 0px;
}
/*MENU DESPLEGABLE*/
ul.menu.nav.navbar-nav.head__menu-content.dropdown-menu.head__submenu-content.open {
  min-width: 220px;
}
/* COOKIES BANNER */
.eu-cookie-compliance-banner {
  background: rgba(0, 0, 0, 0.85);
  opacity: 80%;
}
.eu-cookie-compliance-content {
  text-align: center;
}
.eu-cookie-compliance-content .text-align-center {
  color: #fff;
  margin: 0 auto;
  font-size: 1em;
  margin-top: 10px;
  margin-bottom: 10px;
}
#cookiesPolicyLink {
  color: #fff;
  text-decoration: underline;
}
.eu-cookie-compliance-agree-button {
  background-color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  margin-bottom: 10px;
}
button.agree-button.eu-cookie-compliance-agree-button {
  border-radius: 20px;
  color: white;
  padding: 5px 20px 5px 20px;
}

#sliding-popup.sliding-popup-bottom
  .eu-cookie-compliance-banner
  .eu-cookie-compliance-content
  .eu-cookie-compliance-message
  p {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}
.eu-cookie-compliance-content button {
  border: 2px solid #fff;
  border-radius: 20px;
  color: white;
  padding: 5px 20px 5px 20px;
  background-color: rgba(0,0,0,0.85);
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.eu-cookie-compliance-content button:hover {
  background: #004378;
}
.eu-cookie-compliance-message {
  max-width: 100%;
  float: none;
}

.eu-cookie-compliance-message a {
  color: white;
  text-decoration: underline;
  font-family: "Montserrat", sans-serif;
}
.eu-cookie-compliance-message a:hover {
  background: #004378;
}
.eu-cookie-compliance-buttons {
  max-width: 100%;
  float: none;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}
.tblCookie tr td {
  padding: 10px;
  font-size: 0.8em;
}

/* Extraído de la vista Convocatorias - Procedimientos */
.table-responsive td,
.table-responsive th {
  text-align: start !important;
}
.table-responsive td a {
  text-align: start;
  color: #8b9496 !important;
  font-family: "Roboto Medium", sans-serif !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  letter-spacing: 1px !important;
  line-height: 24px !important;
  text-decoration: none !important;
}
.search-filter__container div:first-child::after,
.search-filter div div#edit-secondary .panel-body div:first-child::after {
  content: "";
}

.form-control,
.form-text,
.form-email {
  display: block;
  width: 100%;
  height: 36px;
  padding: 12px 12px;
  margin-bottom: 12px;
  line-height: 1.42857;
  color: #1a2e35;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
}

.form-textarea {
  display: block;
  width: 100%;
  padding: 12px 12px;
  margin-bottom: 12px;
  line-height: 1.42857;
  color: #1a2e35;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
}

.webform-button--submit {
  color: #fff;
  background-color: #f1315b;
  border-color: #ef1948;
}

.btn-primary:focus,
.btn-primary.focus,
.webform-button--submit:focus {
  color: #fff;
  background-color: #ef1444;
  border-color: #800923;
}

.webform-button--submit:hover {
  color: #fff;
  background-color: #ef1444;
  border-color: #be0d34;
}

.webform-button--submit:active,
.webform-button--submit.active,
.open > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #ef1444;
  border-color: #be0d34;
}

@media only screen and (max-width: 768px){
  
  .filter-menu fieldset .fieldset-wrapper label{
    font-size: 15px;
  }
  
  .filter-menu fieldset .fieldset-wrapper div[id|='edit-field-fecha-fin-plazo-value'] div label.control-label{
    width: 100%;
  }
}