.help{
  &__info-support{
    letter-spacing: 0;
    line-height: 24px;
    width: 650px;
    margin-right: auto;
    margin-left: auto;
    // padding-top: 32px;
    }
  &__container{
    
  }
}

@media only screen and (max-width: 768px){
  .help{
    &__info-support{
      width: 100%;
    }
  }
}