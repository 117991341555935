.article{
	&__container{
		width: 100%;
		position: relative;
	}
	&__content{
		padding: 60px 210px 0px 100px;
		margin-bottom: 32px;
	}
  &__background-tittle{
		color: rgba(243,243,243,0.72);
		font-family: $font-family-roboto-bold;
		font-size: 100px;
		font-weight: 900;
		letter-spacing: 12px;
		line-height: 104px;
	}
	&__title{
		color: $color-text-primary;
		font-family: $font-family-titles;
		font-size: 40px;
		// font-weight: 500;
		letter-spacing: 0;
		line-height: 48px;
		margin: 0px 0px 32px;
	}
	&__paragraph{
		margin-bottom: 32px;
	}
	&__section{
		// color: #0F6ED8;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 24px;
		text-align: justify;
		margin-bottom: 16px;
	}
	&__image{
	  height: 441px;
  	  width: 652px;	
	}
	&__ico{
    height: 13px;
		width: 13px;
		cursor: pointer;
	}
	&__ico-content{
    text-align: center;
    img {
      display: initial;
    }
	}
	&__table{
		width: 682px;
		th:first-child {
			text-align: start;
		}
		td:first-child {
			text-align: start;
		}
    
	}
}

@media only screen and (max-width: 1280px){
	.article{
		&__content{
			padding: 30px 50px 0px 50px;
		}
	}
}

@media only screen and (max-width: 1024px){
	.article{
		&__content{
			padding: 40px 80px 0px 80px;
		}
		.image__principal {
			width: 90%;
			margin-top: 32px;
		}
	}
}

@media only screen and (max-width: 768px){
	.article{
		&__content{
			padding: 0px 20px;
		}
		&__title{
			font-size: 30px;
		}
		&__section{
			font-size: 14px;
		}
	}
}