.newsletter{
    table{
        a{
            font-size: 13px;
        }
    }
    #edit-options {
        display: flex;
        align-items: baseline;
        margin-bottom: 20px;
    }
    #edit-options div:first-child {
            margin-right: 32px;
    }
    // #edit-options label {
    //     color: #0098c3;
    //     text-transform: uppercase;
    // }
    .form-type-email {
        display: flex;
       align-items: center;
        // flex-direction: column;
        // align-items: start;
        & .form-email{
            // width: 100%;
            width: 320px;
            // margin-left: 20px;
        }
        & .control-label{
            margin-right: 20px;
            &:after{
                content: ':';
            }
        }
    }
    input[type="radio"] {
        border: 1px solid black;
    }
    
    // input[type=radio]:checked {
    //     border: 1px solid #0098c3;
    //     background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_radio-alert.svg);
    // }
}

@media only screen and (max-width: 768px){
    .newsletter{
        .form-type-email {
            flex-direction: column;
            align-items: start;
            & .form-email{
                width: 100%;
                 margin-left: 0;
            }
        }
    }
}