.login{

  &__content {
    display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&__title {
		margin: 0;
		font-family: $font-family-roboto-light;
		font-size: 18px;
	}
	&__input{
		margin-bottom: 16px;
		margin-left: 8px;
	}
}