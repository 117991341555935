.head {
  display: inline-flex;
  width: 100%;
  flex-direction: column;

  &__envolture {
    width: 100%;
    display: flex;
    // margin-bottom: 32px;
  }

  &__conteiner-left {
    width: calc(100% - 278px);
    margin-top: 22px;
    display: flex;
    flex-wrap: wrap;
  }

  &__head {
    display: inline-flex;
    margin-bottom: 22px;
    align-items: center;
    order: 1;
  }

  &__title {
    padding-top: 4px;
    color: #dcdcdc;
    font-size: 52px;
    margin: 0;
    line-height: 36px;
    font-family: $font-family-roboto-bold;
    text-transform: uppercase;
    text-decoration: none;
  }

  &__menu {
    width: 100%;
    order: 1;

    & li {
      list-style: none;
    }
  }

  &__menu-content {
    display: inline-flex;
    width: 100%;
    padding-left: 30px;
    padding-right: 14px;
    margin: 0;
    justify-content: space-between;

    & li {
      &:hover {
        & .head__submenu-content {
          & .head__menu-item {
            &:hover:before {
              background-image: none;
            }
          }
        }
      }
    }
  }

  &__menu-item {
    list-style: none;
    text-decoration: none;
    padding-bottom: 10px;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    // &:hover, &:focus, &:active{
    //   &:before {
    //     content: '';
    //     height: 20px;
    //     width: 35px;
    //     background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_underline.svg);
    //     background-repeat: no-repeat;
    //     width: 100%;
    // 	 background-position-x: center;
    //     position: absolute;
    //     transform: translateY(8px) translateX(0px);
    // 	background-size: auto  34px;

    // 	}
    // }
    // &:before{
    //    display: none;
    // }
  }

  &__underline {
    &:before {
      content: "";
      height: 20px;
      // width: 35px;
      background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_underline.svg);
      background-repeat: no-repeat;
      width: 100%;
      background-position-x: center;
      position: absolute;
      transform: translateY(8px) translateX(-4px);
      background-size: auto 34px;
    }
  }

  &__menu-item-title {
    color: $color-text-therciary;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.8px;
    text-decoration: none;
    // padding: 4px;
    padding: 4px;
    position: relative;

    &:hover,
    &:focus,
    &:active {
      &:before {
        content: "";
        height: 20px;
        // width: 35px;
        background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_underline.svg);
        background-repeat: no-repeat;
        width: 100%;
        background-position-x: center;
        position: absolute;
        transform: translateY(8px) translateX(-5px);
        background-size: auto 34px;
      }
    }

    &.head__submenu-title {
      &:hover {
        color: $color-text-secundary;
      }
    }
  }

  &__image {
    width: 450px;
    margin-right: 26px;
  }

  &__submenu-content {
    display: none;
    padding: 20px 16px;
    padding-left: 0;
    border-radius: 0 0 4px 4px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px 0 #dcdcdc;
    // position: relative;    
    margin-top: 20px;
    margin-left: -8px;
    position: absolute;
    z-index: 99;

    & .head__menu-item {
      position: initial;
    }

    &.open {
      & a {
        &:before {
          display: none;
        }
      }
    }
  }

  &__submenu-item {
    list-style: none;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: #616e8f;
    font-size: 13px;
    letter-spacing: 0.8px;
    line-height: 15px;

    &:hover {
      color: #616e8f;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__submenu-item-title {
    color: #616e8f;
    text-decoration: none;
  }

  &__conteiner-right {
    width: 278px;
    border: 2px solid #f3f3f3;
    border-radius: 2px;
    background-color: #ffffff;
    height: 149px;
  }

  &__date-time {
    background-color: $color-text-quintery;
    height: 96px;
    display: flex;
    flex-direction: column;
    padding: 16px 32px;
    justify-content: flex-end;

    & .head__title {
      display: none;
    }
  }

  &__time {
    width: 100%;
    color: $color-text-primary;
    font-size: 34px;
    letter-spacing: 1.89px;
    line-height: 40px;
    margin-bottom: 2px;
  }

  &__date {
    width: 100%;
    color: $color-text-primary;
    font-size: 12px;
    letter-spacing: 0.8px;
    line-height: 14px;
    text-transform: uppercase;
  }

  &__seach {
    color: $color-text-therciary;
    font-size: 13px;
    letter-spacing: 0.8px;
    line-height: 16px;
    height: 48px;
    border-bottom: 2px solid #f3f3f3;
  }

  &__menu-button {
    display: none;
  }

  &__search-button {
    content: url(../images/icons/ic_search.svg);
    display: flex;
    left: calc(100% - 42px);
    top: 32px;
    height: 46px;
    width: 50px;
  }

  &__search-field {
    text-align: end;
    color: $color-text-therciary;
  }

  &__search-text {
    color: $color-text-therciary;
    font-size: 13px;
    letter-spacing: 0.8px;
    line-height: 16px;
  }

  &__menu-selected {
    color: $color-text-primary;
    font-family: var(--font-family-bold);
  }

  &__date-time-envolture {
    flex-direction: column;
    display: flex;
  }
}

.head__conteiner-left {
  .logo-header {
    float: left;
    margin-bottom: 22px;
    margin-right: 26px;
    order: 0;
    display: flex;
    flex-wrap: wrap;

    > div {
      display: flex;
      flex-wrap: wrap;

      > div {
        margin-block: auto;
      }
    }

    .media__image {
      max-width: 450px;
    }

    .paragraph {
      margin-right: 5px;
    }

    img {
      max-width: 450px;
      height: auto;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .head__head {
    // flex-direction: column;
    margin-bottom: 0;
  }
  .head__search-ico {
    width: calc(100% - 29%);
  }
  .head__image {
    margin-bottom: 8px;
  }
  .head__menu {
    display: none;
    width: 278px;
    position: absolute;
    right: 0;
    // transform: translateY(46px);
    padding-top: 26px;
    padding-bottom: 50px;
    background-color: $color-text-quintery;
    z-index: 999;

  }


  .head__menu-button {
    display: block;
    background-color: #ffff;
    border: none;
    border-left: 2px solid #f3f3f3;
    font-family: $font-family-roboto-bold;
    text-transform: uppercase;
    // width: 50px;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding: 6px 12px;

    &:hover:before {
      content: "";
      height: 20px;
      width: 35px;
      background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_underline.svg);
      background-repeat: no-repeat;
      position: absolute;
      transform: translateY(10px);
      background-size: auto 34px;
    }
  }
  .head__seach {
    display: inline-flex;
    width: 100%;
  }
  // .head__menu-button:after{
  //              content: '---';
  //              display: block;
  //              color: #D80F3B;
  //              text-align: center;
  //              transform: translateY(5px);
  // }
  .head__menu-content {
    flex-direction: column;
  }
  .head__menu-item {
    margin-bottom: 26px;

    &::before {
      background-image: none;
    }
  }

  /*      */
  .head__menu-item:hover:after {
    content: "";
  }
  .head__menu-selected:after {
    content: "";
  }

  .head__title {
    margin-left: 20px;
    // line-height: 46px; */
    font-size: 45px;
    line-height: 67px;
  }
  .head__submenu-content {
    position: relative;
    width: calc(100% + 57px);
    margin-left: -29.5px;
  }
  .head__submenu-item {
    padding-left: 30px;
  }
  .head__menu-content {
    padding: 0;
  }
  .head__menu-item {
    padding: 0 30px;
  }
  .head__menu-item:hover .head__menu-item-title {
    color: $color-text-primary;
    font-family: var(--font-family-bold);
  }
  .head__menu-item:active .head__menu-item-title {
    color: $color-text-primary;
    font-family: var(--font-family-bold);
  }
  .head__conteiner-right {
    width: 340px;
    height: fit-content;
  }
  .head__conteiner-left {
    width: calc(100% - 340px);
    margin-top: 0;
  }
  .head__menu-content {
    /* width: 340px; */
  }
  .head__menu {
    width: 340px;
  }
  .head__time {
    font-family: var(--font-family-bold);
  }
  .input__search .input-field-search {
    height: 45px;
  }
  .head__menu-item:hover:before,
  .head__menu-item:focus:before,
  .head__menu-item:active:before {
    background-image: none;
  }
}

@media only screen and (max-width: 992px) {
  .head__image {
    width: 400px;
  }

  .head__conteiner-left {
    .logo-header {
      .media__image {
        max-width: 375px;
      }

      img {
        max-width: 375px;
        height: auto;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .head {
    &__envolture {
      flex-direction: column;
    }

    &__date-time-envolture {
      min-width: 146px;
    }
  }
  .head__submenu-content {
    padding: 10px;
  }
  .head__search-ico {
    width: calc(100% - 23%);

    & .head__search-text {
      width: 100%;
    }
  }
  .head__head {
    /* height: 64px; */
  }
  .head__title {
    display: none;
  }
  .head__conteiner-left {
    width: 100%;
  }
  .head__conteiner-right {
    width: 100%;
  }
  .head__date-time .head__title {
    display: block;
    font-size: 36px;
    line-height: 32px;
    margin-left: 8px;
    padding-top: 0;
  }
  .head__date-time {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 17px;
    height: 80px;
  }
  .head__menu {
    /* display: none; */
    left: 0;
    width: 100%;
    transform: translateY(130px);
    padding: 20px 0;

    button {
      &.navbar-toggler {
        border: none;
        position: absolute;
        right: 0;
      }
    }
  }

  .head__seach {
    width: 100%;
  }
  .head__menu-button {
    width: 15%;
  }
  .head__menu-item {
    margin-bottom: 20px;
  }
  .head__image {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 480px) {
  .head__image {
    width: 275px !important;
  }
  .head__date-time .head__title {
    font-size: 28px;
  }
  .head {
    &__date-time-envolture {
      min-width: 105px;
    }

    &__menu-button {
      width: auto;
    }

    .input__search {
      width: calc(100% - 60px);
    }
  }

  .head__conteiner-left {
    .logo-header {
      .media__image {
        max-width: 275px;
      }

      img {
        max-width: 275px;
        height: auto;
      }
    }
  }
}

@media only screen and (max-width: 440px) {
  .head__time {
    font-size: 24px;
  }

  .head__conteiner-right .head__date-time .head__title {
    font-size: 20px;
  }
}
