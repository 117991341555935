
$img-ic_dropdown:                     "../images/general/ic_dropdown.svg";
.footer{
	max-width: 1400px;
	width: 100%;
	background: $color-bg-section;
	position: relative;
	box-shadow: 0 -2px 8px 0 rgba(44,86,96,0.08);
	padding: 0px;
	& li {
		list-style: none;

		& a{
			color: #2c5660;
		}
	}
	&__open{
  	display: block;
	}
	&__block{
  	padding: 10px 100px;
	}
	&__content-link{
		display: flex;
		// justify-content: flex-end;
			margin: 0;

			height: 140px;
			width: 760px;
			flex-wrap: wrap;
			flex-direction: column;
	}
	&__link-title{
		font-family: $font-family-roboto-bold;
		color: $color-text-primary;
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 0.8px;
		display: block;
		margin-bottom: 4px;
		text-decoration: none;
		&:hover, &:active, &:focus{
			color: $color-text-primary;
		}
	}
	&__link-item{
		font-size: 12px;
		color: $color-text-secundary;
		text-decoration: none;
		line-height: 24px;
		letter-spacing: 0.5px;
		&:hover, &:active, &:focus{
			color: $color-text-secundary;
		}
	}
	&__content-img{
		display: flex;
		justify-content: flex-end;
		padding: 50px 100px 30px;
		flex-wrap: wrap;
		align-items: center;
	}
	&__envolture-img{
		width: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		mix-blend-mode: multiply;
	}
	&__img{
		display: flex;
		margin-left: 13px;
		// &:first-child {
		// 	margin-left: 0;
		// }
		&--principal {
			width: 193px;
			height: 70px;
			// height: 61px;
			margin: 0;
		}
		&--emp-reg{
			width: 42px;
			height: 64px;
		}
		&--seg-info{
			width: 42px;
			height: 64px;
		}
		&--ofsegint{
			margin-left: 0;
		}
	}
	&__content-menu{
		border-top: 1px solid #DCDCDC;
		padding-top: 0;
		padding-bottom: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 72px;
		text-transform: uppercase;
		color: $color-text-therciary;
	}
	&__list{
    display: flex;
    align-items: center;
		margin: 0;
		list-style: none;
		padding: 0;
		& .footer__menu-item:last-child {
			padding-right: 0;
			margin-right: 0;
			border-right: 0;
		}
	}
	&__menu-item{
		border-right: 1px solid #8B9496; 
		padding-right: 32px;  
		margin-right: 32px;  
		letter-spacing: 0.8px;  
		cursor: pointer;
		line-height: 0px;
		&:first-child{
			margin-right: 32px;
		}
		&::before{
			display: none;
		}
	}
	&__content-reference {
		display: flex;
		justify-content: space-between;
		padding-top: 50px;
		padding-bottom: 0px;
	}
	&__link {
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 0.8px;
		color: $color-text-therciary;
		text-decoration: none;
		&:hover, &:focus, &:active{
			color: $color-text-therciary;
		}
	}
	&__link-list {
		margin-bottom: 4px;
		list-style: none;
	}
	&__link-content {
		list-style: none;
		padding-left: 0;
		margin:0;
		li:before{
			display: none;
		}
	}
	&__content-envolture {
		margin-left: 58px;
		&:last-child {
			min-width: 208px;
		}
		&:first-child{
			margin-left: 0px;
		}
	}
	&__ico{
		content: url(/sites/sede/themes/drpl_sede/src/images/general/ic_add.svg);
		transform: translateY(3px) translateX(-1px);
		display: none;
		width: 12px;
    height: 12px;
	}
}

.footer__content-img{
	display: flex;
	justify-content: flex-end;
	padding: 50px 100px 30px;
	flex-wrap: wrap;
	align-items: center;
}


@media only screen and (max-width: 1280px){
	.footer{
		&__content-envolture{
			margin-left: 25px;
		}
		&__img{
			&--principal{
				width: 180px;
			}
			&--ofsegint{
				width: 160px;
			}
			&--datos-abierto{
				width: 160px;
			}
			&--admin-elect{
				width: 160px;
			}
			&--ens{
				width: 46px;
			}
			&--union-europea{
				width: 44px;
			}
		}
		&__block{
			padding-right: 50px;
			padding-left: 50px;
		}
		&__menu-item{
			margin-right: 18px;
			padding-right: 18px;
			&:first-child{
				margin-right: 18px;
			}
		}
		&__content-link{
			width: 690px;
		}
	}
}

@media only screen and (max-width: 1024px){
	.footer{
		&__block{
			padding-right: 50px;
			padding-left: 50px;
		}
		&__content-envolture {
			margin-left: 20px;
		}
		&__link-title{
			color: $color-text-secundary;
			font-family: $font-family-roboto-black;
		}
	}
}

@media only screen and (max-width: 992px){
  .footer{
		&__block{
			padding-right: 40px;
			padding-left: 40px;
			padding-top: 0;
		}
		&__content-reference{
			flex-direction: column;
			padding-bottom: 26px;
			padding-top: 40px;
		}
		&__link-list{
			margin-left: 0;
			&:first-child {
				margin-left: 0;
			}
		}
		&__content-envolture{
			width: calc(100% / 3);
			text-align: start;
			margin-left: 8px;
		}
		&__content-link{
			width: 100%;
			justify-content: space-between;
		}
		&__img{
			margin-left: 14px;
			&--principal{
				margin-bottom: 32px;
				margin-left: 0;
				width: 176px;

			}
			&--ofsegint{
				width: 154px;
			}
			&--datos-abierto{
				width: 154px;
			}
			&--admin-elect{
				width: 154px;
			}
			&--ens{
				width: 48px;
			}
			&--union-europea{
				width: 48px;
			}
		}
	}


	.footer__menu-item{
			padding-right: 14px;
			margin-right: 14px;
	}
	.footer__content-envolture:first-child{
			margin-left: 0px;
			min-width: 254px;
	}
	.footer__content-menu{
			display: flex;
			flex-direction: column-reverse;
			padding: 16px 70px;
			height: auto;
	}
	.footer__list:first-child{
			text-transform: lowercase;
			font-size: 10px;
	}
	.footer__list:last-child{
			margin-bottom: 8px;
			flex-wrap: wrap;
			justify-content: center;
	}
	.footer__menu-item{
			padding-right: 8px;
			margin-right: 8px;
			margin-bottom: 4px;;
	}
	.footer__link{
			font-size: 12px;
	}
}

@media only screen and (max-width: 768px){
	.footer{
		&__block{
			padding-right: 24px;
			padding-left: 24px;
		}
		&__img{
			margin-left: 18px;
			margin-bottom: 16px;
		}
	}
    .footer__img--ofsegint{
        width: 110px;
    }
    .footer__img--datos-abierto{
        width: 110px;
    }
    .footer__img--admin-elect{
         width: 110px;
    }
    .footer__img--ens{
        width: 40px;
    }
    .footer__img--union-europea{
        width: 40px;
    }
    .footer__img--principal{
			width: 130px;
			height: auto;
			margin-bottom: 28px;
    }
    .footer__img--principal{
        margin-left:0;
    }
    .footer__content-link{
				flex-direction: column;
				flex-wrap: nowrap;
				height: 100%;
    }
    .footer__content-envolture{
        margin-left: 0px;
				width: 100%;
				margin-bottom: 16px;
    }

    .footer__list{

    }


    .footer__list:first-child .footer__menu-item:first-child .footer__link{
        text-transform: initial;
    }


	.footer__link-title{
        font-size: 13px;
        height: 16px;
        display: inline-block;
        justify-content: space-between;
        align-items: center;
        letter-spacing: 1px;
				// font-weight: 600;
				text-transform: uppercase;
    }

    .footer__link-list{
        margin-bottom: 30px;
    }
    .footer__link-content{
        display: none;
				margin-top: 4px;
				margin-bottom: 16px;
    }
	.footer__link-item{
		font-size: 11.5px;
    }
    .footer__content-envolture{
				 margin-left: 0px;
				//  margin-bottom: 0;
    }
    .footer__open{
        display: block;
    }
    .footer__ico{
        display: block;
        float: right;
    }   
		.footer__envolture-img{
			flex-wrap: wrap;
		}
		.footer__ico--open{
			content: url(/sites/sede/themes/drpl_sede/images/general/ic_less.svg);
			width: 12px;
			height: 12px;
			transform: translateY(4px) translateX(-1px);
		}
		.footer__img--emp-reg {
			width: 33px;
			height: 49px;
		}
}

@media only screen and (max-width: 480px){
    .footer__img{
        margin-left: 7px;
    }
}