.citizen-folder{
	&__conteiner-card{
		  padding: 0px 55px 32px  55px;
		  display: flex;
		  flex-direction: column;
		  width: 50%;
		  align-items: center;
		  .card-notification__container{
			  &:hover{
				  .citizen-folder__ico--conv-process-ad{
					  &:before{
						  content: '';
						  width: 65px;
						  height: 65px;
						  background-image: url(/sites/sede/themes/drpl_sede/images/global/ic_pc-show-hover.svg);
					  }
				  }
				  .citizen-folder__ico--licitation{
					  &:before{
						  content: '';
						  width: 65px;
						  height: 65px;
						  background-image: url(/sites/sede/themes/drpl_sede/images/global/ic_paper-pencil.svg);
					  }
				  }
			  }
		  }
	  }
	  
	  &__image{
		  width: 100%;
		//   width: 50%;
	  }
	  &__container{
		  display: flex;
		  justify-content: space-between;
		  margin-bottom: 64px;
			position: relative;
			padding-top: 40px;
	  }
	  &__ico{
		  &--conv-process-ad{
			  &:before{
				  content: '';
				  background-image: url(/sites/sede/themes/drpl_sede/images/global/ic_pc-show.svg);
				  width: 60px;
				  height: 60px;
				  display: block;
				//   margin-right: 10px;
				  background-repeat: no-repeat;
				  margin-top: 5px;
				  background-size: cover;
				  margin-right: 20px;
			  }
		  }
		  &--licitation{
			  &:before{
				  content: '';
				  background-image: url(/sites/sede/themes/drpl_sede/images/global/ic_paper-pencil.svg);
				  width: 60px;
				  height: 60px;
				  display: block;
				//   margin-right: 10px;
				  background-repeat: no-repeat;
				  margin-top: 5px;
				  background-size: cover;
				  margin-right: 20px;
			  }
		  }
	  }
  }
	
	@media only screen and (max-width: 1280px){
		.citizen-folder{
			&__conteiner-card{
				// margin: 0px 25px 32px 25px;
			}
			&__image{
				width: 52%;
			}
		}
	}

	@media only screen and (max-width: 992px){
		.citizen-folder{
			&__container{
				width: auto;
				flex-direction: column;
				align-items: center;
			}
			&__conteiner-card{
				width: 100%;
				margin-bottom: 32px;
				/* padding: 0; */
			}
			&__image {
				width: 100%;
				padding: 30px;
			}
		}
	}

	@media only screen and (max-width: 992px){
		.citizen-folder{
			&__conteiner-card{
				padding: 0;
			}
		}
	}
	@media only screen and (max-width: 768px){
		.citizen-folder{
			padding-top: 0;
			&__ico--conv-process-ad:before{
				width: 54px;
				height: 54px;
			}
			&__ico--licitation:before{
				width: 61px;
				height: 54px;
			}
		}
	}