//form buzon etico
.webform-submission-buzon-etico-form {
  legend {
    border-bottom: 0;
    margin-bottom: 0;
  }

  .webform-type-webform-email-confirm {
    margin: 0;
  }
  .webform-button--submit {
    max-width: 138px;
    margin-top: 15px;
  }

  input[data-drupal-selector|="edit-file-remove-button"] {
    max-width: 138px;
    margin-top: 15px;
  }

  .form-managed-file {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
  }

  .form-item .form-select {
    width: 100%;
    padding: 12px 12px;
    margin-bottom: 12px;
    line-height: 1.42857;
    color: #1a2e35;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  #edit-processed-text ul li:before {
    content: "";
    height: 6px;
    width: 6px;
    background-color: #1a2e35;
    display: block;
    -webkit-transform: translateX(-20px) translateY(14px);
    transform: translateX(-20px) translateY(14px);
  }

  .messages--error {
    border: 1px solid #ff4949;
    border-radius: 4px;
    background-color: #ffeded;
    padding: 1rem;
  }
}

//Formulario canal de denuncias
.webform-submission-buzon-etico-form {
  .form-managed-file {
    .form-file{
      padding-left: 0;
    }

    .form-submit{
      max-width: fit-content;
      color: #000;

      &:hover{
        color:#8B9496;
      }
    }

    .form-checkbox{
      position:relative;
      top: 41px;
      z-index: 3;

      &:focus{
        outline: auto;
        outline-offset: 0;
      }
    }
    .option {
      // &::before {
      //   content: "\e5ca";
      //   color: #fff;
      //   font-weight: normal;
      //   font-family: "Material Icons";
      //   font-size: 14px;
      //   position: relative;
      //   left: 1px;
      //   transform: translateY(-50%);
      //   text-decoration: none;
      //   top:-37px;
      //   z-index: 2;
      // }

      &::before {
        content: "";
        display: block;
        position: relative;
        transform: translateY(-50%);
        left: 0;
        z-index: 1;
        width: 16px;
        top: 33px;
        height: 16px;
        // border: 2px solid #909090;        
        background: #fff;
        background-image: none;
        margin: 0;
      }

      span{
        padding-left: 24px;
      }
    }
  }
}


[data-drupal-messages] {
  background: none!important;

  [aria-label="Mensaje de error"] {
    background-color: #ff2a24; 
    border-radius: 5px;
    color: white; 
    padding: 0;
    width: fit-content;
    margin: 0 14px;
    [role="alert"]{
      background-color: #ff2a24; 
      border-radius: 6px;
    }
  }
}

.email-tfa-verify-form{
  padding: 0 30px;
  input{
    width: auto;

    &[value="Cancelar"] {
      border-radius: 6px;
    }
  }

  .form-submit{
    margin-top: 24px;
  }
}
