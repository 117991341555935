/* ======================================================================
	  Fonts
	======================================================================== */

	@font-face {
		font-family: 'Roboto Regular';
		
		src: url('/sites/sede/themes/drpl_sede/fonts/roboto-regular.eot');
		src: url('/sites/sede/themes/drpl_sede/fonts/roboto-regular.eot#iefix') format('embedded-opentype'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-regular.woff2') format('woff2'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-regular.woff') format('woff'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-regular.ttf') format('truetype'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-regular.svg#roboto-regular') format('svg');
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: 'Roboto Medium';
		src: url('/sites/sede/themes/drpl_sede/fonts/roboto-medium.eot');
		src: url('/sites/sede/themes/drpl_sede/fonts/roboto-medium.eot#iefix') format('embedded-opentype'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-medium.woff2') format('woff2'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-medium.woff') format('woff'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-medium.ttf') format('truetype'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-medium.svg#roboto-medium') format('svg');
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: 'Roboto Medium Italic';
		src: url('/sites/sede/themes/drpl_sede/fonts/roboto-mediumitalic.eot');
		src: url('/sites/sede/themes/drpl_sede/fonts/roboto-mediumitalic.eot#iefix') format('embedded-opentype'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-mediumitalic.woff2') format('woff2'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-mediumitalic.woff') format('woff'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-mediumitalic.ttf') format('truetype'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-mediumitalic.svg#roboto-mediumitalic') format('svg');
		font-weight: normal;
		font-style: normal;
	}
	
	@font-face {
		font-family: 'Roboto Black';
		src: url('/sites/sede/themes/drpl_sede/fonts/roboto-black.eot');
		src: url('/sites/sede/themes/drpl_sede/fonts/roboto-black.eot#iefix') format('embedded-opentype'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-black.woff2') format('woff2'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-black.woff') format('woff'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-black.ttf') format('truetype'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-black.svg#roboto-black') format('svg');
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: 'Roboto Black Italic';
		src: url('/sites/sede/themes/drpl_sede/fonts/roboto-blackitalic.eot');
		src: url('/sites/sede/themes/drpl_sede/fonts/roboto-blackitalic.eot#iefix') format('embedded-opentype'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-blackitalic.woff2') format('woff2'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-blackitalic.woff') format('woff'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-blackitalic.ttf') format('truetype'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-blackitalic.svg#roboto-blackitalic') format('svg');
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: 'Roboto Bold';
		src: url('/sites/sede/themes/drpl_sede/fonts/roboto-bold.eot');
		src: url('/sites/sede/themes/drpl_sede/fonts/roboto-bold.eot#iefix') format('embedded-opentype'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-bold.woff2') format('woff2'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-bold.woff') format('woff'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-bold.ttf') format('truetype'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-bold.svg#roboto-bold') format('svg');
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: 'Roboto Bold Italic';
		src: url('/sites/sede/themes/drpl_sede/fonts/roboto-bolditalic.eot');
		src: url('/sites/sede/themes/drpl_sede/fonts/roboto-bolditalic.eot#iefix') format('embedded-opentype'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-bolditalic.woff2') format('woff2'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-bolditalic.woff') format('woff'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-bolditalic.ttf') format('truetype'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-bolditalic.svg#roboto-bolditalic') format('svg');
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: 'Roboto Italic';
		src: url('/sites/sede/themes/drpl_sede/fonts/roboto-italic.eot');
		src: url('/sites/sede/themes/drpl_sede/fonts/roboto-italic.eot#iefix') format('embedded-opentype'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-italic.woff2') format('woff2'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-italic.woff') format('woff'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-italic.ttf') format('truetype'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-italic.svg#roboto-italic') format('svg');
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: 'Roboto Light';
		src: url('/sites/sede/themes/drpl_sede/fonts/roboto-light.eot');
		src: url('/sites/sede/themes/drpl_sede/fonts/roboto-light.eot#iefix') format('embedded-opentype'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-light.woff2') format('woff2'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-light.woff') format('woff'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-light.ttf') format('truetype'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-light.svg#roboto-light') format('svg');
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: 'Roboto Light Italic';
		src: url('/sites/sede/themes/drpl_sede/fonts/roboto-lightitalic.eot');
		src: url('/sites/sede/themes/drpl_sede/fonts/roboto-lightitalic.eot#iefix') format('embedded-opentype'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-lightitalic.woff2') format('woff2'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-lightitalic.woff') format('woff'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-lightitalic.ttf') format('truetype'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-lightitalic.svg#roboto-lightitalic') format('svg');
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: 'Roboto Thin';
		src: url('/sites/sede/themes/drpl_sede/fonts/roboto-thin.eot');
		src: url('/sites/sede/themes/drpl_sede/fonts/roboto-thin.eot#iefix') format('embedded-opentype'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-thin.woff2') format('woff2'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-thin.woff') format('woff'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-thin.ttf') format('truetype'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-thin.svg#roboto-thin') format('svg');
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: 'Roboto Thin Italic';
		src: url('/sites/sede/themes/drpl_sede/fonts/roboto-thinitalic.eot');
		src: url('/sites/sede/themes/drpl_sede/fonts/roboto-thinitalic.eot#iefix') format('embedded-opentype'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-thinitalic.woff2') format('woff2'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-thinitalic.woff') format('woff'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-thinitalic.ttf') format('truetype'),
		url('/sites/sede/themes/drpl_sede/fonts/roboto-thinitalic.svg#roboto-thinitalic') format('svg');
		font-weight: normal;
		font-style: normal;
	}

	$font-family-default         : "Roboto Regular";
	$font-family-roboto			 : "Roboto Regular";
	$font-family-roboto-bold     : "Roboto Bold";
	$font-family-roboto-regular	 : "Roboto Regular";
	$font-family-roboto-medium	 : "Roboto Medium";
	$font-family-roboto-black	 : "Roboto Black";
	$font-family-roboto-light    : "Roboto Light";

	$font-family-titles			 :  $font-family-roboto-medium;
	$font-family-subtitles		 :  $font-family-roboto-medium;
	$font-family-section		 :  $font-family-roboto-regular;
	$font-family-body			 :	$font-family-roboto-regular;
	$font-family-link		 	 :	$font-family-roboto-medium;
	// $font-family-bold 			 :  $font-family-roboto-bold;
	// $font-family-black			 :	$font-family-roboto-black;