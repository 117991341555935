.filter-menu {
  &__label,
  & fieldset .fieldset-wrapper label {
    color: $color-text-therciary;
    font-family: $font-family-roboto-medium;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 24px;
    margin-right: 20px;
    text-transform: uppercase;
  }
  &__selected {
    color: $color-text-primary;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 24px;
  }
  &__container,
  & fieldset .fieldset-wrapper div[id|="edit-field-fecha-fin-plazo-value"] {
    display: flex;
    div {
      &:nth-child(1) {
        display: none;
      }
      display: flex;
      position: relative;
      label.control-label {
        //label.filter-menu{
        display: flex;
        text-align: center;
        cursor: pointer;
        flex-direction: column;
        align-items: center;
        width: 128px;
        input {
          height: 2px;
          background-color: transparent;
          border-color: transparent;
          transform: translateY(36px);
          width: 33px;
          margin-top: 0;
          border-radius: 0;
          &:hover,
          &:active {
            background-color: $color-success;
          }
        }
        input:checked {
          background-color: $color-success;
        }
      }
      // }
    }
  }

  & .form--inline {
    // display: flex;
    align-items: center;
    & fieldset legend {
      display: none;
    }
    & div[data-drupal-selector|="edit-actions"] {
      width: 100%;
    }
  }

  & div[id|="edit-secondary"] {
    border: none;
    border-radius: 0;
    margin-bottom: 0;
    & div[class|="panel-heading"] {
      background-color: transparent;
      border: none;
      padding: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .filter-menu {
    margin-top: 16px;
  }
  .filter-menu .form--inline div[data-drupal-selector|="edit-actions"] {
    width: auto;
    position: absolute;
    bottom: -70px;
  }

  .search-filter__envolture,
  .search-filter div div[id|="edit-secondary"] {
    width: auto;
    bottom: -55px;
    right: auto;
    left: 200px;
  }

  .search-filter__head-filter,
  .search-filter button[id|="edit-submit-convocatorias-y-ayudas"] {
    float: none;
    transform: none;
  }
  .search-filter__head-filter,
  .search-filter input[id|="edit-submit-convocatorias-y-ayudas"] {
    float: none;
    -webkit-transform: none;
    transform: none;
  }
  .view-filters.form-group {
    height: 0;
  }
}

@media only screen and (max-width: 768px){
  
  .filter-menu fieldset .fieldset-wrapper label{
    font-size: 15px;
  }
  
  .filter-menu fieldset .fieldset-wrapper div[id|='edit-field-fecha-fin-plazo-value'] div label.control-label{
    width: 100%!important;
  }
}