.documents{
  &__text{
		color: $color-text-secundary;
		font-size: 13px;
		letter-spacing: 1px;
		line-height: 16px;
	}
	&__ico{
		height: 48px;
		width: 48px;
		&--sello{
			background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_sellotiempo.svg);
			background-size: cover;
		}
		&--downloand{
			background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_download.svg);
			background-size: cover;
		}
	}
	&__table, table{
		th, td {
			&:first-child{
				text-align: start;
				width: 50%;
			}
		}
	}
}