/* ======================================================================
	BASE:
======================================================================== */

// Global:
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html{
  position: relative;
}

body {
   background-color: #ffffff;
   color: $color-text-primary;
   font-family: $font-family-body;
   font-size: $font-size-base;
   //coenteiner:
   margin-right: auto;
   margin-left: auto;
   box-sizing: border-box;
   position: relative;

  // @include rem(font-size, $font-size-base);
  // line-height: $line-height-base;
}

ul,
ol {
  list-style: none;
  // margin: 0;
  // padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}


h3, h3 a{
  font-size: 20px;
  color: $color-text-therciary;
  font-family: $font-family-subtitles;
  font-weight: normal;
  text-align: start;
  margin-top: 0;
  margin-bottom: 16px;
}

h4{
  color: #1A2E35;
  font-family: $font-family-subtitles;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 24px;
  text-align: start;
  margin-top: 0;
}

@media only screen and (max-width: 768px){
  h3, h4{
    font-size: 17px;
  }
}

//General
.desktop {
  @include respond-to(md) {
    display: none !important;
  }
}

.mobile {
  display: none;
  @include respond-to(md) {
    display: block !important;
  }
}

.detect-orientation {
  display: none;
  // color: $color-secondary-brand;
  text-align: center;
  background-color: rgba(0, 0, 0, .8);
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  // z-index: map-get($zindex, detect-orientation);
  p {
    margin: 0 0 20px;
  }
  span {
    @include css3(transform, translate(-50%, -50%));
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
  }
}


//Only for iPhone
input,
textarea,
select {
  border-radius: 0;
  -webkit-border-radius:0px;
}
input,
textarea,
select {
  -webkit-appearance: none;
}


input {
    width: 100%;
    padding: 6px 12px;
    border-color: $color-text-therciary;
    color: $color-text-therciary;
    // border-radius: 6px;
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html[lang="zh-hans"] {
    word-break: keep-all;
}

strong{
  font-family: $font-family-roboto-bold;
  font-weight: normal;
}

//Personality:
.open, .collapse.in {
  display: block !important;
}
.open-flex{
  display: flex !important;
}
.visbility{
  visibility: visible !important;
}
.max-height{
	height: 100%;
}
.max-width{
	height: 100%;
}
.middle-width{
	width: 50%;
}
.middle-height{
	width: 50%;
}

.hide{
  display: none;
}


//GRID:
.margin-top-m{
  margin-top: 32px;
}
.margin-bottom-s{
  margin-bottom: 16px;
}
.margin-bottom-m{
  margin-bottom: 32px;
}
.margin-bottom-l{
  margin-bottom: 64px;
}
.no-txt-decoration{
  text-decoration: none;
}


.padding-bottom-s{
  padding-bottom: 16px;
}
.padding-bottom-m{
  padding-bottom: 32px;
}


.padding-top-m{
  padding-top: 44px;
}

.main-height{
  min-height: 366px;
}
.btn-primary{
  border-color: $color-buttons;
}

.alert-dismissable .close, .alert-dismissible .close {
  right: -4px;
}
.alert {
  width: 100% !important;
  }

.flex{
  display: flex;
}
.justify-center{
  justify-content: center;
  margin-left: 20px;
}
.margin-enlace-convo{
  margin-left: 20px;
  margin-right: 0px;
}
.region.region-content {
  margin-top: 32px;
}
.form-item-message {
  margin-bottom: 16px;
}
.glyphicon-search{
    content: '';
    background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_search.svg);
    height: 16px;
    width: 17px;
    background-size: cover;
    &:hover, &:active, &:focus{
      background-color: white;
      border-color: white;
    }
    &:before{
      content: '';
    }
}
@media only screen and (max-width: 1280px){
  body {
    font-size: 14px;
  }
}

.table>thead>tr>th {
  border: none;
}
td {
  min-width:160px;
}
td .documents__ico img{
  display:block;
  margin-left: auto;
  margin-right:auto;
}
// comment