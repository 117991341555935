/* ======================================================================
  SETTINGS GENERALS
======================================================================== */

$bg-body							 :		#FFFFFF;


// Colors:
//--------------------------
$color-bg-section      :    #F6F6F6;


$color-primary         :    #D80F3B;
$color-primary-light   :    #F1315B;
$color-primary-dark    :    #C60E36;

$color-secondary       :    #0FD8AC;
$color-secondary-light :    #00FFCE;

$color-terciary        :    #FFCE00;
$color-terciary-dark 	 :    #EBC214;

$color-buttons		   	 : 	  #F1315B;

/*messages*/
$color-error           :    #F1315B;
$color-warning         :    #FF0000;
$color-info            :    #6fadd3;
$color-success         :    #0FD8AC;

/*text */
$color-text-primary    :    #1A2E35;
$color-text-secundary  :    #2C5660;
$color-text-therciary  :    #8B9496;
$color-text-cuarty     :    #DCDCDC;
$color-text-quintery   :    #F3F3F3;
$color-text-sexty   	 :    #F6F6F6;
$color-text-default    :    #1A2E35;

/*link */
$color-link-primary    :    #28448F;
$color-link-secundary  :    #0F6ED8;
$color-link-therciary  :    #0FA0D8;

// $color-primary-brand:						#000;
// $color-secondary-brand:					#FFF;
// $color-third-brand:							#EB0505;


// Font Base
// -------------------------
$font-family-base				:   Arial, Helvetica, sans-serif;
$font-size-base         :   16px;
$line-height-base       :   1.428571429; // 20/14


// Others
// -------------------------
$content-width					:		1366px;


// Max breakpoint
$max-breakpoint					:		1140;

// Grid breakpoints
$breakpoints: (
	ss										: 	320px,
	xs										:		480px,
	sm										:		767px,
	md										:		992px,
	lg										:		1024px,
	xg										:		1280px
);



// Zindex site blocks
$zindex: (
  header								:		9999,
  footer								:		10,
  menu-header-mobile__nav: 	8888,
  hamburger							:		9999,
  menu-lang-list				:		900,
  menu-login-location		:		900,
  menu-login-location-icon: 1000,
  back-to-top						:		9999,
  home-slider-list-mobile: 	10,
  block                 :   5,
  home__slider--triangle-down: 999,
  menu-select-list-home	:		99,
  pre-home							:		999999,
  cookies-block					:		888888,
  detect-orientation		:		9999999
);

//Path image:
$path-image-develop     :   '/sites/sede/themes/drpl_sede/images';
$path-image-production  :   '/images';

// $path_image             :   $path-image-production;
$path-image             :   $path-image-develop;

