table, .table {
  border-collapse: collapse;
  width: 100%;
  border: 2px solid #F3F3F3;
  border-radius: 4px;
  background: linear-gradient(135.63deg, #FFFFFF 0%, #FEFEFE 48.75%, #F6F6F6 100%);
  thead{
    color: $bg-body	;
    font-family: $font-family-roboto-light;
    letter-spacing: 0.8px;
    line-height: 24px;
    // border: 2px solid #F1315B;
    // text-align: left;
    font-size: 12px;
    text-transform: uppercase;
    th, a{
      color: $bg-body	;
      font-family: $font-family-roboto-light;
      letter-spacing: 0.8px;
      line-height: 24px;
      // text-align: left;
      font-size: 12px;
      text-transform: uppercase;
      &:hover{
        color: $bg-body	;
      }
    }
  }
  tbody{
    color: $color-text-secundary;
    font-size: 13px;
    letter-spacing: 1px;
    line-height: 16px;
  }
  th {
    background: $color-primary-light;
  }
  th, td {
    padding: 10px 14px;
    text-align: center;
    vertical-align: middle;
    // min-width: 200px;
    a{
      text-decoration: none;
      &:hover{
        text-decoration: none;
      }
    }
  }
  tr {
    background: rgb(252,252,252);
    // border-bottom: 1px solid #DCDCDC;
  }
  tr:hover {
    background: $color-text-quintery ;
  }
  &__ico{
    height: 13px;
    width: 13px;
    cursor: pointer;
  }
  &__ico-content{
    text-align: start;
  }
  &__link{
    height: 24px;
    width: 90px;
    color: $color-link-secundary;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 24px;
  }
  &__link-text{
    color: $color-text-therciary;
    font-family: $font-family-roboto-medium;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 24px;
  }
  &__one{
    th{
      text-transform: uppercase;
      &:nth-child(2), &:first-child{
        text-align: start!important;
      }
      &:last-child{
        text-align: end;
      }
      a{
        color: $bg-body	;
        letter-spacing: 0.8px;
        line-height: 24px;
        text-align: left;
        font-size: 12px;
      }
    }
    td:first-child{
      text-align: start!important;
      a{
        color: $color-link-secundary;
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 24px;
      }
    }
    td:nth-child(2){
      text-align: start!important;
      color: $color-text-therciary;
      font-family: $font-family-roboto-medium;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 24px;
      text-decoration: none;
      & a{
        color: $color-text-therciary;
        font-family: $font-family-roboto-medium;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 24px;
        text-decoration: none;
        &:hover{
          text-decoration: none;
        }
      }
    }
    td:last-child{
      text-align: end;
      color: $color-text-secundary;
      font-size: 13px;
      letter-spacing: 1px;
      line-height: 16px;
    }
    th, td {
      min-width: 200px;
    }
  }
  &__second {
    & table th, table td{
      &:first-child{
        display: flex;
        flex-direction: column;
        text-align: start;
      }
    }
  }
  &__terciary{
    & th, & td{
      &:first-child{
        display: flex;
        flex-direction: column;
        text-align: start;
      }
    }
  }

}
.table-responsive{
  &__shadow{
    display: none;
  }
}

.table-hover>tbody>tr:hover{
  background-color: #e5e5e5;
}

@media only screen and (max-width: 992px){
  table, .table{
    &__one{
      th, td{
        min-width: 165px;
        vertical-align: middle;
      }
      td:nth-child(2) {
        font-size: 13px;
      }
    }
    &__second{
      & table{width: 100%;}
    } 
  }
}

@media only screen and (max-width: 767px){

table, .table{
  &__one{
    th{
      text-transform: uppercase;
      &:nth-child(2), &:first-child{
        text-align: start!important;
      }
      &:last-child{
        text-align: start!important;
      }
      a{
        color: $bg-body	;
        letter-spacing: 0.8px;
        line-height: 24px;
        text-align: left;
        font-size: 12px;
      }
    }
    td:first-child{
      text-align: start!important;
      a{
        color: $color-link-secundary;
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 24px;
      }
    }
    td:nth-child(2){
      text-align: start!important;
      color: $color-text-therciary;
      font-family: $font-family-roboto-medium;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 24px;
      text-decoration: none;
      & a{
        color: $color-text-therciary;
        font-family: $font-family-roboto-medium;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 24px;
        text-decoration: none;
        &:hover{
          text-decoration: none;
        }
      }
    }
    td:last-child{
      // text-align: end;
      color: $color-text-secundary;
      font-size: 13px;
      letter-spacing: 1px;
      line-height: 16px;
    }
    th, td {
      min-width: 200px;
    }
  }
  &__second{
    td{
      align-items: start;
    }
  }
}

  .table-responsive{
    scrollbar-face-color: $color-primary-light;
    scrollbar-highlight-color: transparent;
    scrollbar-arrow-color: $color-primary-light;
    scrollbar-track-color: $color-primary-light;;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      padding: 0px 10px;
    }
    &::-webkit-scrollbar-track {
      border: 1px solid lightgray;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: $color-primary-light;
      border-radius: 8px;
    }
    &__shadow{
      position: absolute;
      left: 100%;
      z-index: 3;
      display: block;
      right: 0;
      width: 8px;
      border: none;
      box-shadow: -32px -1px 67px 23px rgba(255,255,255,0.96);
      -webkit-box-shadow: -32px -1px 67px 23px rgba(255,255,255,0.96);
      -moz-box-shadow: -32px -1px 67px 23px rgba(255,255,255,0.96);
    }
  }


  //adpatacion:
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    // font-size: .8em;
    //text-align: right;
    min-height: 44px;
    text-align: start;
    align-items: start;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    left: 0;
    width: 45%;
    padding: 8px 10px;
    padding-right: 10px;
    white-space: nowrap;
    background: $color-primary-light;
    letter-spacing: 0.8px;
    line-height: 24px;
    text-align: left;
    font-size: 12px;
    color: white;    
    height: 100%;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
  
    tbody td{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding-left: 50% !important;
    }

}

//Tabla de convocatorias y ayudas filtro de categorias
.view-documentos-por-categoria table tbody tr td, .view-documentos-por-categoria > table > thead > tr > th:first-child {
  text-align: start;
  width: 50%;
 }
 
 .view-documentos-por-categoria table caption {
     color: #1A2E35;
     font-family: "Roboto Medium", sans-serif;
     font-size: 20px;
     font-weight: 500;
     letter-spacing: 1px;
     line-height: 30px;
     text-align: start;
     margin-top: 0;
 }