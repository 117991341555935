.sitemap {
    padding: 0px 100px;
    & .content{
        padding-left: 20px;
        margin-bottom: 32px;
    }
    &-menu{
        max-height: 640px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        .leaf a, .expanded span {
            color: $color-text-therciary;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 1px;
            line-height: 24px;
            font-family: $font-family-link;
            display: block;
            margin-bottom: 20px;
            &:hover, &:focus, &:active{
                color:$color-text-therciary;
            } 
        }
    }

     & .sitemap-box ul ul{
        margin-bottom: 24px;
    }
    & .sitemap-menu .sitemap-menu .leaf a {
        color: $color-link-secundary;;
        font-family: $font-family-link;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        margin-bottom: 10px;
        &:hover, &:focus, &:active{
            color: $color-link-therciary;;
        } 
    }
}

@media only screen and (max-width: 1280px){
    .sitemap {
        padding: 0px 80px;
    } 
}

@media only screen and (max-width: 1024px){
    .sitemap {
        padding: 0px 20px;
        width: 100%;
    } 
}

@media only screen and (max-width: 768px){
    .sitemap {
        padding: 0px 20px;
        width: 100%;
        & .content{
            padding-left: 16px;
            margin-bottom: 32px;
        }
        &-menu{
            max-height: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            .leaf a, .expanded span {
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 16px;
                &:hover, &:focus, &:active{
                    color:$color-text-therciary;
                } 
            }
        }
    
         & .sitemap-box ul ul{
            margin-bottom: 20px;
        }
        & .sitemap-menu .sitemap-menu .leaf a {
            font-family: $font-family-link;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 20px;
            margin-bottom: 8px;
        }
    }
}