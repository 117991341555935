.process-old{
  &__content{
		padding: 10px 10px;
		margin-bottom: 10px;
		width: 100%;
		padding-left: 0;
	}
	&__title, .views-field.views-field-nothing .views-label.views-label-nothing{
		font-family: $font-family-roboto-black;
		font-size: 13px;
		// font-weight: 900;
		letter-spacing: 1px;
		line-height: 16px;
		color:$color-text-secundary;
	}
	&__tag, .views-field.views-field-field-expediente-convoca .field-content{
		color: $color-primary;
		font-size: 12px;
		letter-spacing: 1px;
		line-height: 24px;
	}
	&__text, .views-field.views-field-title .field-content{
		color: $color-text-primary;
		// font-size: 16px;
		letter-spacing: 0;
		line-height: 24px;
	}
}

@media only screen and (max-width: 1280px){
	.process-old{
		&__content {
			// width: 490px;
		}
	}
}

@media only screen and (max-width: 992px){
	.process-old{

	}
}