.pagination {
  margin-top: 32px;
  &__next {
    text-align: end;
    right: 0;
    position: absolute;
    margin-right: 20px;
  }
  &__prev {
    text-align: start;
    left: 0;
    position: absolute;
    margin-left: 20px;
  }
  &__text {
    text-decoration: none;
    color: $color-text-primary;
    font-family: $font-family-roboto-bold;
    text-transform: uppercase;
    font-size: 15px;
    display: inline-flex;
    height: 20px;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: $color-text-therciary;
    }
  }
  &__ico {
    width: 17px;
    height: 27px;
    display: block;
    &--next {
      margin-left: 6px;
    }
    &--prev {
      margin-right: 6px;
      width: 15px;
      height: 18px;
    }
  }
  > li,
  > li:first-child,
  > li:last-child {
    > a,
    > span {
      border-radius: 50%;
      margin: 0 4px;
    }
  }
  > li.pager__item--next > a,
  > li.pager__item--last > a,
  > li.pager__item--prev > a,
  > li.pager__item--previous > a,
  > li.pager__item--first > a,
  > li.pager__item--next > a span,
  > li.pager__item--last > a span,
  > li.pager__item--ellipsis > span,
  > li.pager__item--previous > a span,
  > li.pager__item--prev > a span,
  > li.pager__item--first > a span {
    background: none;
    border: none;
  }
}

.views-element-container footer {
  text-align: center;
  margin-bottom: 40px;
}

@media only screen and (max-width: 768px) {
  .block-views-blockconvocatorias-y-ayudas-block-1 .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .block-views-blockconvocatorias-y-ayudas-block-1
    .pagination
    .pager__item--first {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  .block-views-blockconvocatorias-y-ayudas-block-1
    .pagination
    .pager__item--previous {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  .block-views-blockconvocatorias-y-ayudas-block-1
    .pagination
    .pager__item--next {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  .block-views-blockconvocatorias-y-ayudas-block-1
    .pagination
    .pager__item--last {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
}
