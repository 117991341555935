.input__search label {
  display: inline-block;
}

.footer__link {
  color: #676e6f;
}

.focus-outline {
  outline: -webkit-focus-ring-color auto 1px;
}

/* 1.3.1 - Información y relaciones */
.form-required:after {
  color: red;
  content: "*";
  display: inline-block;
  margin-left: 5px;
}
/* 4.1.2 Nombre, función, valor */
#sede-newsletter-form .radio input[type="radio"],
#sede-newsletter-form .radio-inline input[type="radio"] {
  all: revert;
}
#sede-newsletter-form .radio label {
  padding-left: 0;
}

/* Especial styles for convert images to html native */
.fig_table_1 {
  display: table;
  margin-left: 70px;
  margin-right: 10px;
}
.fig_table_1 .fig_table_1_cell {
  display: table-cell;
  vertical-align: middle;
}
.fig_table_1 .fig_table_1_cell .fig_table_1_cell_content {
  background-color: #f2f2f2;
  padding: 10px 5%;
  padding-left: 17%;
  min-height: 130px;
  border-radius: 20px;
}
.fig_table_1 .fig_circle {
  display: block;
  position: relative;
}
.fig_table_1 .fig_circle p {
  display: block;
  position: absolute;
  top: 0px;
  left: -70px;
  height: 130px;
  width: 130px;
  border-radius: 50%;
  border: 2px solid #af1b1d;
  background-color: #f2f2f2;
  color: #af1b1d;
  text-align: center;
  padding-top: 40px;
  font-weight: bold;
}

table.fig_table_2 {
  table-layout: fixed;
}
table.fig_table_2,
table.fig_table_2 th,
table.fig_table_2 td {
  background-color: #fff;
}
table.fig_table_2 th {
  border-radius: 10px;
  border: 2px solid #af1b1d;
  color: #af1b1d;
}
table.fig_table_2 ul {
  list-style: disc;
  margin-left: 10px;
  padding: 0;
  text-align: left;
}
table.fig_table_2 td {
  vertical-align: top;
}

/* nav main */
li.head__menu-item a {
  display: inline-block;
  padding: 0;
  white-space: normal;
  color: #8b9496;
}
li.head__menu-item a:hover {
  color: #8b9496;
}

/* bloque temporal css */
.view-id-convocatorias_y_ayudas .view-empty {
  margin-bottom: 140px;
}

#edit-submit-buscar span.icon.glyphicon.glyphicon-filter {
  display: none;
}
.reload-captcha-wrapper {
  padding-bottom: 20px;
}
/* */
h2 > a.limit__title {
  pointer-events: none;
}
.subcription .button,
.subcription .btn {
  color: #fff;
}
.filter-menu
  fieldset
  .fieldset-wrapper
  div[id|="edit-field-fecha-fin-plazo-value"]
  div
  label.control-label {
  width: 158px;
}
@media only screen and (max-width: 767px) {
  .view-convocatorias-y-ayudas .table-responsive {
    margin-top: 50px;
  }

  .view-convocatorias-y-ayudas .filter-menu {
    /*margin-top: 0px;*/
  }
}
.card-notification__container-video {
  text-align: left !important;
}
@media only screen and (min-width: 992px) {
  .card-notification__container-video p {
    padding-left: 100px;
  }
}
/* */
@media only screen and (max-width: 440px) {
  .head__time {
    font-size: 24px;
  }
}
@media only screen and (max-width: 768px) {
  .search-general__panel {
    margin: 0px 0 80px 0;
  }
  .search-general__envolture {
    padding-top: 0px;
  }
  .documents__table th:first-child,
  .documents__table td:first-child,
  .documents table th:first-child,
  .documents table td:first-child {
    width: auto;
  }
  .tipografy__container-background {
    top: 50px;
  }
  .navbar-lateral__container {
    display: block;

    position: static;

    margin-top: 15px;
  }
  table td::before,
  .table-responsive table td::before {
    white-space: unset;
    line-height: 16px;
  }
  .table-large-label td {
    padding-bottom: 120px;
  }
  h1.limit__code {
    padding-top: 90px;
  }

  .table__one td:nth-child(2),
  .table__one td:nth-child(2) {
    font-size: 13px;
  }
  .view-id-convocatorias_y_ayudas .view-filters.form-group {
    height: 0;
    z-index: 1;
    position: absolute;
    margin-top: -45px;
  }
  .view-id-convocatorias_y_ayudas .view-empty {
    margin-top: 50px;
  }
  #views-exposed-form-convocatorias-y-ayudas-block-4.search-filter
    div
    div[id|="edit-secondary"]
    .panel-body {
    bottom: -88px;
  }
  .view-convocatorias-y-ayudas
    .views-exposed-form-convocatorias-y-ayudas-block-4
    .filter-menu {
    margin-top: 20px;
  }
  .view-convocatorias-y-ayudas
    #views-exposed-form-convocatorias-y-ayudas-block-1.filter-menu {
    margin-top: 0px;
  }
}

/*media all*/
.head__image {
  max-width: 450px;
  width: inherit;
}
@media only screen and (max-width: 992px) {
  .head__image {
    max-width: 400px !important;
  }
}
@media only screen and (max-width: 480px) {
  .head__image {
    max-width: 275px !important;
  }
}
/* end bloque temporal css */

/* styles for view /otros-procedimientos / display_id block_4 */
#block-views-block-convocatorias-y-ayudas-block-4 .table-responsive td,
#block-views-block-convocatorias-y-ayudas-block-4 .table-responsive th {
  text-align: start !important;
}
#block-views-block-convocatorias-y-ayudas-block-4 .table-responsive td a {
  text-align: start;
  color: #8b9496 !important;
  font-family: "Roboto Medium", sans-serif !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  letter-spacing: 1px !important;
  line-height: 24px !important;
  text-decoration: none !important;
}

#block-views-block-convocatorias-y-ayudas-block-4 .search-filter {
  transform: translateY(-60px) !important;
}

#block-views-block-convocatorias-y-ayudas-block-4
  .search-filter__container
  div:first-child::after,
#block-views-block-convocatorias-y-ayudas-block-4
  .search-filter
  div
  div[id|="edit-secondary"]
  .panel-body
  div:first-child::after {
  content: "";
}

/* newsletter */
.table-servicio-alertas-td-1 {
  width: 206px;
}
.table-servicio-alertas-td-2 {
  width: 522px;
}

/* menu footer ineteres */
@media only screen and (max-width: 992px) {
  .footer__content-envolture {
    width: auto;
    text-align: start;
    margin-left: 0px;
    min-width: auto;
  }
}

@media only screen and (min-width: 768px) {
  .footer__content-link {
    width: auto;
    height: auto;
  }
  .footer__link-parent {
    column-count: 3;
    padding: 0;
  }
  .footer__content-envolture {
    margin: 0;
    padding: 0;
  }
}
/* procedimientos */
.other-process__detail-envolture .limit__code span {
  display: block;
}

.limit__code {
  font-size: 40px;
  color: #1a2e35;
  font-family: "Roboto Medium", sans-serif;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 50px;
  text-align: start;
}
