.table{
    &-servicio-alertas{
		width: 730px;
		border: 1px solid #F1315B;
		border-radius: 0;
				border-collapse: separate;
				margin-bottom: 6px;
				font-size: 13px;
        & td:first-child {
			text-transform: uppercase;
			text-align: start;
					border-right: 1px solid #F1315B;
        }
        & td {
						border-bottom: 1px solid  #F1315B;
						// border-bottom: solid #abe8f9 1px;
						text-align: start;
						& p {
							margin-bottom: 0;
						}
        }
        & tr:last-child td{
            border-bottom: none;
				}


    }
}


@media only screen and (max-width: 767px){
	.table{
    &-servicio-alertas{
			border: none;
			margin-bottom: 10px;
			width: 100%;
			&  td {
				width: 100%;
				padding-left: 10px !important;
				border: 1px solid #F1315B;
				// border: solid #abe8f9 1px;
				&:before {
					display: none;
				}
				&:first-child{

				}
			}
			& tbody{
				tr{
					display: flex;
					justify-content: space-between;
					flex-direction: column;
				}
			}
			& tr{
				border-bottom: 3px solid #F1315B;
			}
		}
	}
}


.clickable-row{
	cursor: pointer;
}