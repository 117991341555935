.who-is-sede{
  &__container{
		display: flex;
		align-items: flex-start;
		margin-bottom: 64px;
    h3{
      color:#1A2E35;
    }
    h4{
      color:#8B9496;
      font-size: 107%;
    }
	}	
}

@media only screen and (max-width: 768px){
	.who-is-sede{
		&__container{
			flex-direction: column;
		}
	}
}