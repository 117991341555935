.search-filter{
  transform: translateY(-134px);
  margin-bottom: -45px;
  &__envolture, & div div[id|='edit-secondary']{
    right: 0;
    float: right;
    position: absolute;
  }
  &__container, & div div[id|='edit-secondary'] .panel-body{
    display: none;
    width: 260px;
    border-radius: 0 24px 24px 24px;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 4px 0 rgba(26,46,53,0.04), -2px -2px 1px 0 #FFFFFF, -4px -4px 16px 0 rgba(255,255,255,0.88), 0 4px 16px 0 #DCDCDC;  
    // display: flex;
    flex-wrap: wrap;
    padding: 8px;
    padding-top: 50px;
    padding-bottom: 24px;
    div{
      &:first-child{
        width: 100%;
        &::after{
          content: 'POR FECHA DE FIN DE PLAZO: ';
          color: $color-text-secundary;
          font-size: 13px;
          letter-spacing: 1px;
          line-height: 16px;
        }
      }
      &:nth-child(2){
        margin-right: 4px;
        width: 100%;
      }
      &:nth-child(3){
        width: 100%;
      }
      // width: calc(50% - 2px);
    }
    
  }
  &__filter{
    width: 100%;
    height: 48px;
    box-shadow: 0 2px 4px 0 #DCDCDC;
    margin-bottom: 8px;
    & .input{
      width: 211px;
      height: 48px;
    }
    & .button{
      height: 40px;
      width: 40px;
    }
  }
  &__content{
    padding: 6px;
  }
  &__search, & div div[id|='edit-secondary'] .panel-body div:first-child input{
    height: 40px;
    margin-bottom: 8px;
    color: $color-text-therciary;
    font-size: 13px;
    letter-spacing: 0.8px;
    line-height: 16px;
    margin-top: 10px;
    width: 100%;
    &:before{
      height: 40px;
      width: 40px;
      background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_search.svg);
    }
  }
  &__label, & div div[id|='edit-secondary'] .panel-body label{
    color: $color-text-secundary;
    font-size: 13px;
    letter-spacing: 1px;
    line-height: 16px;
  }
  &__date-content, & div div[id|='edit-secondary'] .panel-body div input{
    height: 48px;
    display: flex;
    width: 100%;
  }
  &__check-button{
    color: $color-text-therciary;
    font-family: $font-family-roboto-medium;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.86px;
    line-height: 16px;
    padding-left: 20px;
  }
  &__check-selected{
    color: $color-text-secundary;
  }
  &__button, & div div[id|='edit-secondary'] div a{
    display: block;
    height: 48px;
    width: 48px;
    border: 2px solid #F3F3F3;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    border-radius: 100px 0 0 100px;
    background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_filter.png);
    float: right;
    color: transparent;
    //ddd
    z-index: 999;
    position: relative;
    background-repeat: no-repeat;
    background-size: 22px 18px;
    background-position: center;
    background-color: white;
    &:active, &:focus{
      background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_reset.svg);
      background-repeat: no-repeat;
      background-size: 22px 18px;
      background-position: center;
      & .button__filter{
        display: none;
      }
    }
  }
  &__head-filter, & button[id|='edit-submit-convocatorias-y-ayudas']{
    float: right;
    transform: translateX(-46px);
    text-align: end;
    display: flex;
    // justify-content: flex-end;
    align-items: center;
    height: 48px;
    border: 2px solid #F3F3F3;
    // color: $color-text-therciary;
    font-size: 13px;
    letter-spacing: 0.8px;
    line-height: 16px;
    text-transform: uppercase;
    // background: #ffffff;
    width: 201px;
    justify-content:center;
    background: #F1315B;
    color: #fff;
    & .glyphicon{
      display: none;
    }
    // &:hover, &:active, &:focus{
    //   background: #ffffff;
      
    // }
  }
  input{
    color: $color-text-secundary;
    font-size: 13px;
    border: 1.5px solid #F3F3F3;
    // border-radius: 4px;
  }
  &__general{
    
  }
}


@media only screen and (max-width: 768px){
  .search-filter{
    &__container, & div div[id|='edit-secondary'] .panel-body{
      float: left;
      left: -208px;
      bottom: -283px;
    }
  }
}

// @media only screen and (max-width: 768px){
//   .search-filter{
//     &__head-filter, & button[id|='edit-submit-convocatorias-y-ayudas']{
//       width: calc(100% - 36px);
//     }
//   }
// }
//  .search-filter fieldset .fieldset-wrapper div[id|='edit-field-fecha-fin-plazo-value']{ background:aqua; }   , & fieldset .fieldset-wrapper div[id|='edit-field-fecha-fin-plazo-value']
