.cardHeader {
    position: relative;
    &__envolture{
        display: flex;
        justify-content: center;
        padding: 0 20px;
        height: 450px;
        align-items: center;
        position: relative;
    }
    &__content {
        min-height: 170px;
        height: 238px;
        width: 360px;
        margin-right: 20px;
        background: linear-gradient(135.63deg, #FFFFFF 0%, #FEFEFE 48.75%, #F6F6F6 100%);
        box-shadow: -2px -2px 2px 0 rgba(26,46,53,0.04), 2px 2px 2px 0 #FFFFFF, 4px 4px 16px 0 rgba(255,255,255,0.72), -4px -4px 16px 0 rgba(26,46,53,0.04);
        &:last-child {
          margin-right: 0;
        }
        &:hover, &:active, &:focus {
					height: 258px;
					width: 380px;
					box-shadow: 2px 2px 4px 0 rgba(26,46,53,0.04), -2px -2px 1px 0 #FFFFFF, -4px -4px 30px 0 rgba(255,255,255,0.88), 4px 4px 30px 0 rgba(26,46,53,0.24);
					transition:.25s;
					margin-top: -12px;
				}
    }
    &__container-img{
        width: 100%;
        display: block;
        text-align: center;
        padding: 44px 0; 
    }
    &__title {
        padding: 12px 0;
        display: block;
        color: #FFFFFF;
        width: 100%;
        font-family: $font-family-roboto-bold ;
        font-size: 14px;
        // font-weight: bold;
        letter-spacing: 0.8px;
        line-height: 24px;
        border-radius: 8px 8px 0 0;
        background: $color-primary-light;
        box-shadow: 2px 2px 4px 0 rgba(26,46,53,0.04), -2px -2px 1px 0 #FFFFFF, -4px -4px 34px 0 rgba(255,255,255,0.88), 4px 4px 34px 0 rgba(26,46,53,0.24);
        text-align: center;
    }
    &__image{
        margin-right: auto;
        margin-left: auto;
    }
    &__background {
        position: absolute;
        top: 0;
        width: 100%;
        margin: 0;
        padding: 0;
        background-color: $color-text-sexty ;
        height: 100%;
        z-index: -1;
        &--block {
            height: 50%;
            background-color: white;
        }
    }
}

@media only screen and (max-width: 992px){
	.cardHeader{
		&__envolture{
			flex-wrap: wrap;
			 height: 100%;
		}
		&__content{
			margin-bottom: 22px;
			margin-right: 9px;
			&:hover, &:active, &:focus {
        height: 238px;
				width: 360px;
				margin-top: -6px;
			}
		}
		&__background--block{
			height: 40%;
		}
	}
}

@media only screen and (max-width: 768px){
	.cardHeader{
		&__content{
			width: 100%;
			min-height: auto;
			height: auto;
			margin-right: 0;
			margin-bottom: 16px;
        &:hover, &:active, &:focus {
					& .cardHeader__container-img{
						display: block;
						width: 100%;
					}
					width: 100%;
				}

		}
		&__container-img{
			display: none;
		}
	}
}