.input{
	border: 2px solid #F3F3F3;
	border-radius: 4px;

	&__placeholder{
		font-family: $font-family-roboto;
		font-size: 13px;
		color: $color-text-therciary;
	}
	&__filled{
		font-family: $font-family-roboto-medium;
		font-size: 14px;
		color: $color-text-secundary;
	}
	&__search {
		position: relative;
		width: 100%;
		height: 46px;
		& label{
			display: none;
		}
		.input-text {
			position: relative;
			margin: 0;
		}
		.input-field-search {
			width: 100%;
			width: 227px;
			height: 48px;
			padding-right: 34px;
			border: transparent;
		}
		.input-group-btn {
			& button{
			// 	background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_search.svg);
			 	background-color: white;
			// 	background-size: cover;
			// 	background-repeat: no-repeat;
			 	width: 47px;
			 	height: 49px;
			 	border-color: white;
			 	&:hover, &:active, &:focus{
			 		background-color: white;
			 		border-color: white;
			 }
			}
			// content: url(/sites/sede/themes/drpl_sede/images/general/ic_search.svg);
			// height: 46px;
			// width: 46px;
			// background-color: #ffffff;
			// cursor: pointer;
		}
	}
}
input[type="checkbox"] {
	top: -1px;
	height: 16px;
    width: 16px;
    border: 2px solid $color-text-secundary;
    border-radius: 4px;
    background-color: #ffff;
}

input[type=checkbox]:checked {
    background-color: $color-text-secundary;
    background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_check.svg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2px;
}

input[type="radio"] {
	top: -2px;
	height: 16px;
    width: 16px;
    border: 2px solid $color-text-secundary;
    border-radius: 50%;
    background-color: #ffff;
}

input[type=radio]:checked {
    background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_radio.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

@media only screen and (max-width: 1280px){
	.input{
		&__search{
			& .input-group-btn{
				// transform: translate(241px,-36px);
			}
		} 
	}
}

@media only screen and (max-width: 768px){
	.input{
		&__search{
			& .input-group-btn button{
				width: 30px;
				height: 43px;			
			}
		}
	}
}

@media only screen and (max-width: 480px){
	.input{
		&__search{
			& .input-group-btn button{
				width: 39px;
				height: 47px;			
			}
		}
	}
}