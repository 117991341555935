.button{
    &__login {
        background-color: $color-buttons;
        border-radius: 6px;
        text-align: center;
            padding: 6px 32px;
            height: 40px;
            color: #ffff;
        text-decoration: none;
            text-transform: uppercase;
            font-family: $font-family-roboto-bold;
            &:hover, &:focus, &:active{
                background-color: $color-primary;
            }
		}
		&__filter{
				width: 8px;
				height: 8px;
				background: $color-primary-light;
				position: absolute;
				border-radius: 50%;
				left: 11px;
				bottom: 25px;
		}
		&__up{
			background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_btnfloat.svg);
			height: 48px;
			width: 48px;
			border-radius: 50%;
			background-color: $color-buttons;
			//box-shadow: 2px 2px 4px 0 rgba(26,46,53,0.04), -2px -2px 1px 0 #FFFFFF;			
			// top: 50%;
			// left: 50%;
			// transform: translate(-50%,-50%);
			display: none;
			position: fixed;
			z-index: 1000;
			bottom: 1em; /** Sepración inferior **/
			right: 1em; /** Separación derecha **/
			padding: .3em; /** Relleno **/
			box-shadow: rgba(0, 0, 0, .5) 0 1px 2px, rgba(0, 0, 0, .25) 0 1px 7px; /** Sombra **/

		}
		
		& .glyphicon-log-in:before{
			background-image: url(/sites/sede/themes/drpl_sede/images/general/ic_login.svg);
			width: 14px;
			height: 14px;
			background-size: cover;
			content: '';
			background-repeat: no-repeat;
			display: block;
		}
}

.boton-subcriber{
	background-color: $color-buttons;
    border-color: $color-buttons;
    color: #FFF;
    position: relative;
    width: 120px;
    text-align: start;
	&:before {
		background-image: url(/sites/sede/themes/drpl_sede/images/global/ic_notificacion.svg);
		content: '';
		background-size: 14px 14px;
		position: absolute;
		background-repeat: no-repeat;
		width: 15px;
		height: 16px;
		right: 8px;
		bottom: 7px;
	}
	&:hover, &:active, &:focus{
		background-color: $color-buttons;
		border-color: $color-buttons;
		color: #FFF;	
	}
}