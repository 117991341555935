//#### UTILS #####
// Generate a modifiers for css3 rules
@mixin css3($property, $value) {
  @each $prefix in -webkit-, -moz-, -ms-, -o-, '' {
    #{$prefix}#{$property}: $value;
  }
}


// Convert unit px to rems
@function parseInt($n) {
  /* 2 */
  @return $n / ($n * 0 + 1);
}

@mixin rem($property, $values) {
  $px : ();
  $rem: ();

  @each $value in $values {

    @if $value == 0 or $value == auto {
      $px : append($px , $value);
      $rem: append($rem, $value);
    }

    @else {
      $unit: unit($value);
      $val: parseInt($value);

      @if $unit == "px" {
        $px : append($px,  $value);
        $rem: append($rem, ($val / 16 + rem));
      }

      @if $unit == "rem" {
        $px : append($px,  ($val * 16 + px));
        $rem: append($rem, $value);
      }
    }
  }

  @if $px == $rem {
    #{$property}: $px;
  } @else {
    #{$property}: $px;
    #{$property}: $rem;
  }
}


/**
 *
 * For Headings behaviors responsive
 * http://sassbreak.com/viewport-relative-headings-with-sass/
 *
 */
// This could be one of your media query breakpoint variables
$wide-screen: "(min-width: #{$max-breakpoint}px)";

// Function
@function get-vw($target) {
  // 1 vw is equal to 1% of the viewport width
  $vw-context: ($max-breakpoint * .01) * 1px; // 1% viewport width
  @return ($target/$vw-context) * 1vw;
}

// Mixin
@mixin vw($size) {
  font-size: get-vw($size);
  // Prevent font-size from getting too big
  @media #{$wide-screen} {
    font-size: $size;
  }
}


//Animation
@mixin animation($animation) {
  -webkit-animation: $animation;
  -moz-animation: $animation;
  -o-animation: $animation;
  animation: $animation;
}
@mixin animation-fill-mode($fill-mode){
  -webkit-animation-fill-mode: $fill-mode;
  -moz-animation-fill-mode: $fill-mode;
  -o-animation-fill-mode: $fill-mode;
  animation-fill-mode: $fill-mode;
}
@mixin animation-duration($duration) {
  -webkit-animation-duration: $duration;
  -moz-animation-duration: $duration;
  -o-animation-duration: $duration;
  animation-duration: $duration;
}
@mixin delay($delay) {
  -webkit-animation-delay: $delay;
  -moz-animation-delay: $delay;
  -o-animation-delay: $delay;
  animation-delay: $delay;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} { @content; }
  @-moz-keyframes #{$name} { @content; }
  @-ms-keyframes #{$name} { @content; }
  @keyframes #{$name} { @content; }
}


// Cross browser opacity
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}


// Mixin that help you to generate all fonts extensions.
// Example of use
// @include font-face("open_sanslight", "../fonts/OpenSans-Light-webfont", 300, normal);
// @author javasteve99
// @link http://codepen.io/javasteve99/pen/hDxpn

// @param {*}   $font-family  - Name of font family
// @param {*}   $file-path  - Path of fonts
// @param {number}   $font-weight  - Weight of font
// @param {*}   $font-style  - Style of font


@mixin font-face($font-family, $file-path, $font-weight, $font-style) {
  @font-face {
    font-family: $font-family;
    src: url('#{$file-path}.eot');
    src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
    url('#{$file-path}.woff') format('woff'),
    url('#{$file-path}.ttf') format('truetype'),
    url('#{$file-path}.svg##{$font-family}') format('svg');
    font-weight: $font-weight;
    font-style: $font-style;
  }

}


// Mixin breakpoints
@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}


// Mixin Placeholder
/** Example
 *
 * @include placeholder {
 *   font-style:italic;
 *   color: white;
 *   }
 */

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content
  }
  :-moz-placeholder           {
    @content
  }
  ::-moz-placeholder          {
    @content
  }
  :-ms-input-placeholder      {
    @content
  }
}