.subcription{
  & .form-actions{
			margin-top: 32px;
			display: flex;
    	align-items: center;
  }
  & .button, .btn {
    background-color: $color-buttons;
    border-radius: 6px;
    text-align: center;
    padding: 9px 14px;
    // height: 40px;
    color: #ffff;
    text-decoration: none;
    text-transform: uppercase;
		font-family: "Roboto Bold", sans-serif;
    margin-right: 16px;
    border: 0;
    font-size: 14px;
    &:hover, &:focus, &:active{
        color: #ffff;
    }
    & .icon {
      display: none;
    }
  }  
}

@media only screen and (max-width: 480px){
  .subcription{
    & .form-actions{
      flex-direction: column;
      align-items: start;
    }
    & .button, .btn {
      margin-bottom: 10px;
      font-size: 12px;
    }
  }
}