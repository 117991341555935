.support{
  &__content{
		padding: 30px;
		width: 584px;
		border: 2px solid #F3F3F3;
		border-radius: 4px;
		background: linear-gradient(135.63deg, #FFFFFF 0%, #FEFEFE 48.75%, #F6F6F6 100%);
		margin-bottom: 16px;
	}
	&__title, & .field--name-field-titulo-contacto{
		color: $color-primary;
		font-size: 18px;
		letter-spacing: 1px;
		line-height: 24px;
		margin-top: 0;

	}
	&__subtitle, & .field--name-field-descripcion-contacto{
		color: $color-text-secundary;
		font-size: 13px;
		letter-spacing: 0;
		line-height: 16px;
		margin-bottom: 14px;
    margin-top: 20px;
	}
	&__text, & .field--label-above, & .field--name-field-html{
		color: $color-text-primary;
		// font-size: 16px;
		letter-spacing: 0;
		line-height: 24px;
		padding-left: 20px;
		// margin-bottom: 30px;
		margin-bottom: 6px;
		display: flex;
		& div:first-child{
			margin-right: 4px;
			&:after{
				content: ':';
			}
		}

	}
	& .field--name-field-html{
		color: $color-text-primary;
		// font-size: 16px;
		letter-spacing: 0;
		line-height: 24px;
		padding-left: 20px;
		// margin-bottom: 30px;
		margin-bottom: 6px;
		display: flex;
	}
}
@media only screen and (max-width: 1280px){
	.support{
		&__content{
			// width: 50%;
		 }
	}

}

@media only screen and (max-width: 992px){
	.support{
		&__content{
			width: 100%;
		}
	}
}

@media only screen and (max-width: 768px){
	.support{
		&__content{
			padding: 20px;
		  }
			&__title, & .field--name-field-titulo-contacto{
				font-size: 16;
			}
			&__subtitle, & .field--name-field-descripcion-contacto{
				font-size: 12px;
			}
	}
}
