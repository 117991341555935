.search-result{
    &__envolture{
        margin: 30px 0;
        width: auto;
    }
    &__item{
        margin: 16px 0px;
        padding: 16px 0px;
        border-bottom: 2px solid  #F3F3F3;;
    }
}

@media only screen and (max-width: 768px){
    .search-result{
        &__envolture{
            width: 100%;
        }
        // .head__search-ico{
        //     width: 100%;
        // }
    }

}