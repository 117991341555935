.tenders{
	position: relative;
  &__tenders{
		display: inline-flex;
		width: 100%;
		margin-bottom: 32px;
		// padding-top: 44px;
		&--block{
			width: 50%;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
		}
	}
	&__envolture{
		display: flex;
    flex-direction: column;
	}
}

@media only screen and (max-width: 992px){
  .tenders{
		&__tenders{
			flex-direction: column;
			align-items: center;
		}
		&__envolture{
			width: 100%;
		}
	}
}

@media only screen and (max-width: 768px){
	.tenders{
		&__envolture{
			margin-bottom: 32px;
		}
	}
}