
.card {
	&__item{
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 254px;
		width: 230px;
		padding: 40px 20px;
		justify-content: flex-start;
		border-radius: 7.07px;
		background: linear-gradient(135.63deg, #FFFFFF 0%, #FEFEFE 48.75%, #F6F6F6 100%);
		box-shadow: -2px -2px 2px 0 rgba(26,46,53,0.04), 2px 2px 2px 0 #FFFFFF, 4px 4px 14px 0 rgba(255,255,255,0.72), -4px -4px 14px 0 rgba(26,46,53,0.04);
		&:hover, &:active, &:focus{
			height: 280px;
			width: 254px;
			border-radius: 7.79px;
			background-color: $bg-body;
			box-shadow: 2px 2px 4px 0 rgba(26,46,53,0.04), -2px -2px 1px 0 #FFFFFF, -4px -4px 30px 0 rgba(255,255,255,0.88), 4px 4px 30px 0 rgba(26,46,53,0.24);
			transition:.25s;
			margin-top: -12px;
		}
	}
	 
	&__content{
		width: 100%;
		// width: 1156px;
		min-height: 310px;
		display: inline-flex;
		justify-content: space-between;
		// padding: 20px 0;
		margin-bottom: 32px;
		z-index: 5;
	}

	&__envolture {
    display: flex;
	justify-content: center;
	flex-direction: column;
	position: relative;
		& div {
			&:nth-child(4){
				position: relative;
				display: flex;
				justify-content: center;
			}
		}

	}

	&__ico {
    // height: 105.83px;
	// 	width: 106.15px;
	height: 65px;
    width: auto;
    margin-bottom: 40px;
		// margin-bottom: 16px;
	}

	&__title {
		color: $color-link-secundary;
		font-size: 16px;
		// font-weight: 500;
		font-family: $font-family-link;
		letter-spacing: 0;
		line-height: 21.2px;
		text-align: center;
		text-decoration: none;
		width: 100%;
		&:hover {
			text-decoration: underline;
			color: $color-link-secundary;
		}
	}
	&__title-content{
		color: $color-text-primary;
		font-size: 24px;
		// font-weight: 500;
		font-family: $font-family-subtitles;
		letter-spacing: 0;
		line-height: 32px;
		z-index: 5;
		padding: 0 104px;
	}
	&__title-background{
		color: rgba(243,243,243,0.72);
		font-size: 100px;
		// font-weight: 900;
		letter-spacing: 12px;
		line-height: 104px;
		background-color: #fff;
		display: block;
		font-family: $font-family-roboto-bold;
	}
	&__background{
		position: absolute;
		top: 0;
		width: 100%;
		margin: 0;
		padding: 0;
		background-color: $color-text-sexty;
		height: 100%;
		z-index: -1;
		overflow: hidden;
	}
}

@media only screen and (max-width: 992px){
	.card{
		&__title-content{
			padding: 0;
			margin-left: 20px;
		}
	}
}

@media only screen and (max-width: 992px){
	.card{
		&__item{
			height: 228px;
			width: 180px;
			padding: 20px 11px;
			&:hover {
				height: 238px;
				width: 200px;
				border-radius: 7.70px;
				transition:.25s;
				margin-top: -10px;
			}
		}
		&__envolture {
			margin-bottom: 32px;
		}
	}
}

@media only screen and (max-width: 768px){
    .card{
		&__item{
			width: 100%;
			display: flex;
			flex-direction: row;
			padding: 10px 30px;
			height: auto;
			justify-content: stretch;
			margin-bottom: 16px;
			&:hover, &:active, &:focus{
				height: auto;
				width: 100%;
				box-shadow: 2px 2px 4px 0 rgba(26,46,53,0.04), -2px -2px 1px 0 #FFFFFF, -4px -4px 30px 0 rgba(255,255,255,0.88), 4px 4px 30px 0 rgba(26,46,53,0.24);
				transition:.25s;
				margin-top: -6px;
			}
		}
		&__ico{
			margin-bottom: 0;
			margin-right: 20px;
			height: 54px;
			width: 54px;
		}
		&__content{
			flex-direction: column;
			padding: 0 20px;
		}
		&__title{
			text-align: start;
			font-size: 14px;
		}
    }
}





