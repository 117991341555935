.carrousel-info{
	&__container{
		height: 401px;
		width: 578px;
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&__panel{
		position: relative;
		// height: 410px;
		width: 422px;
		border-radius: 8px;
		background: linear-gradient(135.63deg, #FFFFFF 0%, #FEFEFE 48.75%, #F6F6F6 100%);
		box-shadow: -2px -2px 2px 0 rgba(26,46,53,0.04), 2px 2px 2px 0 #FFFFFF, 4px 4px 16px 0 rgba(255,255,255,0.72), -4px -4px 16px 0 rgba(26,46,53,0.04);
		padding: 32px;
		.slick-prev.slick-arrow {
			margin-left: -27px;
		}

		.slick-next.slick-arrow {
			margin-right: -27px;
		}

		.slick-arrow::before {
			color: #8B9496;
		}
	}
	&__head{
		color: $color-link-secundary;
		font-size: 12px;
		letter-spacing: 1px;
		line-height: 24px;
		text-decoration: none;
		&:hover{
			text-decoration: none;
		}
	}
	&__title{
		color: $color-text-primary;
		font-family: $font-family-roboto-medium;
		font-size: 24px;
		// font-weight: 500;
		letter-spacing: 0;
		line-height: 32px;
		margin-bottom: 16px;
	}
	&__paragraph{
		color: $color-text-primary;
		font-size: 16px;
		letter-spacing: 0;
		line-height: 24px;
		margin-bottom: 20px;
	}
	&__container-link{
		display: flex;
		justify-content: space-between;
	}
	&__link{
		color: $color-link-secundary;
		font-family: $font-family-roboto-medium;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center;
		cursor: pointer;
	}
	&__container-button{
		z-index: -99;

		display: inline-flex;
		justify-content: space-between;
		position: absolute;
		width: 100%;
		bottom: 0;
		& span[id|='views_slideshow_controls_text_previous_avisos-block_1']{
			transform: translateX(-81px);
		}
		& span[id|='views_slideshow_controls_text_next_avisos-block_1']{
			transform: translateX(81px);
		}
	    // margin-left: -25%;
		// transform: translateY(-7px);
	}
	&__button{
		height: 42px;
		width: 42px;
		&--next{

		}
		&--prev{

		}
	}
	&__background{
		position: absolute;
    display: flex;
    z-index: -100;
		height: 272px;
		width: 535px;
		border-radius: 8px;
		background-color: $color-text-quintery ;
		box-shadow: -2px -2px 2px 0 rgba(26,46,53,0.04), 2px 2px 2px 0 #FFFFFF, 4px 4px 16px 0 rgba(255,255,255,0.72), -4px -4px 16px 0 rgba(26,46,53,0.04);
	}
}

@media only screen and (max-width: 1024px){
	.carrousel-info{
		&__container-button{
			// width: calc(100% + 116px);
		}
	}
}
@media only screen and (max-width: 992px){
	 .carrousel-info{
			 &__background{
					height: 231px;
					width: 450px;
				}
				&__panel{
					// height: 370px;
					width: 370px;
					padding: 26px;
				}
				&__container-button{
					// width: calc(100% + 43%);
					// margin-left: -22%;
					// transform: translateY(-20px);
				}
				&__paragraph{
					font-size: 14px;
				}
				&__background {
					width: 424px;
				}
				&__button{

				}
				&__title{
					font-size: 20px;
				}
				&__link{
					font-size: 14px;
				}
		}
}

@media only screen and (max-width: 768px){
	.carrousel-info{
		&__container{
			margin-bottom: 20px;
			width: 100%;
		}
		&__background{
			height: 235px;
			width: 95%;
			margin-bottom: 32px;
		}
		&__panel{
			width: 100%;
			padding: 20px;
		}
	}
}

@media only screen and (max-width: 480px){
	.carrousel-info{
		&__button{
			height: 40px;
			width: 36px;
		}
	}
}

.skin-default{
	position: relative
}