.forms{
    &__accesibility{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;
        .form-type-textarea{
            margin-bottom: 16px;
        }
    }
    &__image{
        width: 50%;
    }
    &__contact{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__accesibility-form,  &__contact-form{
        width: 46%;
    }

}

@media only screen and (max-width: 992px){
    .forms{
        &__accesibility, &__contact{
            flex-direction: column;
        }
        &__accesibility-form,  &__contact-form{
            width: 70%;
            margin-bottom: 50px;
        }
    }
}

@media only screen and (max-width: 768px){
    .forms{
        &__accesibility{
            flex-direction: column;
        }
        &__image{
            width: 96%;
            // width: 400px;
            // height: 400px;
       }
       &__accesibility-form,  &__contact-form{
        width: 100%;
        }
    }
}