.alert {
	box-sizing: border-box;
	padding: 16px;
	width: 682px;
	border-radius: 4px;
	margin: 16px 0;
	&__title {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 8px;
		display: block;
		&--info {
			color: $color-text-secundary;
		}
		&.note__title--warning {
			color: $color-primary;
		}
	}
	&__text {
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    color: $color-text-primary;
    font-style: italic;
	}
	&__text-principal {
    font-size: roboto normal;
	}
  	&__warning {
    border: 2px solid rgba(241,49,91,0.16);
    background-color: rgba(241,49,91,0.16);
    box-sizing: border-box;
	}
	&__info {
    border: 2px solid rgba(15,216,172,0.16);
    border-radius: 4px;
    background-color: rgba(15,216,172,0.08);
	}
	&__close {
	/* transform: translateY(-21px) translateX(20px); */
		cursor: pointer;
		float: right;
		&--warning{
			content: url(/sites/sede/themes/drpl_sede/images/general/ic_close_red.svg);
		}
		&--info {
			content: url(/sites/sede/themes/drpl_sede/images/general/ic_close_verde.svg);
		}
	}
}