.proccess{
    &__content{
        letter-spacing: 0;
        line-height: 24px;
        width: 650px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 32px;
    }
}

@media only screen and (max-width: 768px){
    .proccess{
      &__content{
        width: 100%;
      }
    }
  }