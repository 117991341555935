.accesibility, .list-principal{
    & ul{
        padding-left: 40px;
		margin-bottom: 20px;
		& li{
			&:before{
				content: '';
				height: 6px;
				width: 6px;
				background-color: $color-terciary ;
				display: block;
				transform: translateX(-20px) translateY(14px);
			}
		}
    }
}