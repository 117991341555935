.home{
    position: relative;
    &__panel-principal{
        display: flex;
        margin-bottom: 64px;
        justify-content: space-between;
    }
    &__process{
        width: 50%;
    }
    &__advice{
        width: 535px;
        height: 100%;
    }
}

@media only screen and (max-width: 768px){
    .home{
        &__panel-principal{
            display: flex;
            margin-bottom: 32px;
            flex-direction: column;
            align-items: center;
        }
        &__process {
            width: 73%;
        }
        &__advice {
            width: 80%;
        }
    }
}

@media only screen and (max-width: 768px){
    .home{
        &__process {
            width: 96%;
        }
    }
}