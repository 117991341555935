@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.tipografy__title,
h1,
h1 a,
h1 a:hover {
  font-size: 40px;
  color: $color-text-primary;
  font-family: $font-family-titles;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 50px;
  text-align: start;
}
.tipografy__subtitle--principal,
h2,
h2 a,
h2 a:hover {
  //   &--principal{
  font-size: 24px;
  color: $color-text-primary;
  font-family: $font-family-subtitles;
  font-weight: normal;
  text-align: start;
  margin-top: 0;
  margin-bottom: 24px;
  &:hover {
    color: $color-text-primary;
  }
}
.tipografy__subtitle--secundary {
  font-size: 20px;
  color: $color-text-therciary;
  font-family: $font-family-subtitles;
  font-weight: normal;
  text-align: start;
  margin-top: 0;
}
.tipografy__subtitle--tertiary {
  color: $color-text-primary;
  font-family: $font-family-subtitles;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 24px;
  text-align: start;
  margin-top: 0;
}
.tipografy {
  &__section {
    font-size: 18px;
    color: $color-primary;
    font-family: $font-family-roboto;
  }
  &__link {
    font-size: 16px;
    color: $color-link-secundary;
    font-family: $font-family-link;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }
  &__menu {
    font-size: 14px;
    font-family: $font-family-roboto;
    color: $color-text-therciary;
    &:hover {
      color: $color-text-secundary;
      font-family: $font-family-roboto-bold;
    }
    &:active {
      color: $color-text-primary;
      font-family: $font-family-roboto-bold;
    }
  }
  &__container-background {
    position: absolute;
    top: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #ffff;
    height: 100%;
    z-index: -1;
    &--right {
      display: flex;
      justify-content: flex-end;
    }
    &--grey-color {
      background-color: $color-text-sexty;
    }
  }
  &__background {
    color: rgba(243, 243, 243, 0.72);
    font-family: $font-family-roboto-bold;
    font-size: 100px;
    font-weight: 900;
    letter-spacing: 12px;
    line-height: 104px;
    text-transform: uppercase;
    word-break: break-word;
    &--right {
      width: min-content;
    }
    &--no-upper {
      text-transform: none;
    }
    &--bck-light {
      background-color: white;
    }
  }
  &--underline {
    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }
  &--txt-align-left {
    text-align: end;
  }
}

@media only screen and (max-width: 1024px) {
  .tipografy {
    &__background {
      font-size: 80px;
      letter-spacing: 8px;
      line-height: 90px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .tipografy {
    &__background {
      font-size: 70px;
      line-height: 65px;
    }
  }
  .tipografy__title,
  h1,
  h1 a,
  h1 a:hover {
    font-size: 35px;
  }
  .tipografy__subtitle--principal,
  h2,
  h2 a,
  h2 a:hover {
    font-size: 20px;
  }
  .tipografy__subtitle--secundary,
  .tipografy__subtitle--tertiary {
    font-size: 18px;
  }
  h3,
  h4 {
    font-size: 14px;
  }
  .tipografy__section {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .tipografy {
    &__background {
      font-size: 50px;
      letter-spacing: 4px;
      line-height: 70px;
    }
  }
  .tipografy__title,
  h1,
  h1 a,
  h1 a:hover {
    font-size: 26px;
  }
  .tipografy__subtitle--principal,
  h2,
  h2 a,
  h2 a:hover {
    font-size: 16px;
  }
  .tipografy__subtitle--secundary,
  .tipografy__subtitle--tertiary {
    font-size: 17px;
  }
  h3,
  h4 {
    font-size: 14px;
  }
  .tipografy__section {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px){
  .licitation .tipografy__container-background {
    top: 0px;
  }
  
  .tenders .tipografy__container-background {
    top: 0px;
  }
}