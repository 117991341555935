.container, .content{
  &__body{
    padding: 0px 100px;
  }
}
@media only screen and (max-width: 1280px){
    .container{
        &__body{
            padding: 0px 80px;
        } 
    } 
}
@media only screen and (max-width: 1024px){
    .container{
        &__body{
            padding: 0px 20px;
            width: 100%;
          }
    }
}

@media only screen and (max-width: 768px){
    .container{
        &__body{
            padding: 0px 20px;
            width: 100%;
          }
    }
}