#cookiesjsr {
  .cookiesjsr-banner {
    border-top: 3px solid #000000;
    color: #000000;
    //background-color: #8B9496;
    background-color: #FFFFFF;
    font-family: "Roboto Regular";
    font-size: 14px;


    a {
      color: #000000;
      text-decoration: underline;
      &:hover {
        color: #df3c5f;
        text-decoration: none;
      }
    }

    .cookiesjsr-banner--action {
      button {
        border: 2px solid #000000;
        color: #000000;
        &.cookiesjsr-settings {
          background-color: #df3c5f;
          color: #FFFFFF;
        }
      }
    }
  }
}

//#df3c5f

.cookiesjsr-layer {
  font-family: "Roboto Regular";
  font-size: 14px;

  .cookiesjsr-layer--body {
    .cookiesjsr-service-groups {
      .cookiesjsr-service-group {
        &.active {
          button {
            background-color: #df3c5f;
          }
          .cookiesjsr-service-group--content {
            background-color: #FFFFFF;
            color: #000000;
            h3 {
              color: #000000;
            }
            a {
              color: #000000;
              text-decoration: underline;
              &:hover {
                color: #000000;
                text-decoration: none;
              }
            }
            .cookiesjsr-service--always-on {
              span {
                border: 1px solid #000000;
                padding: 10px;
              }
            }
          }
        }
      }
    }
  }
  .cookiesjsr-layer--footer {
    .cookiesjsr-layer--actions {
      button {
        border: 2px solid #000000;
        color: #000000;
        &.save {
          background-color: #df3c5f;
          color: #FFFFFF;
        }
      }
    }
  }

}
