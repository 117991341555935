.card-item-link {
  &__container {
    //		display: flex;
    //    flex-direction: column;
    //		align-items: center;
    min-height: 450px;
  }
  &__content {
    // display: block;
    width: 380px;
    //		background-color: #FEFFFF;
    //		display: inline-flex;
    //		align-items: center;
    // margin-bottom: 14px;
    padding: 16px;
    text-decoration: none;
    //margin-right: 16px;
    margin-left: 0;
    border-radius: 4px;
    transition: 0.25s;
    display: flex;
    align-items: center;
    &:hover,
    &:active,
    &:focus {
      //			height: calc(100% + 14px);
      //			width: calc(380px + 20px);
      //			background-color: $bg-body;
      //			box-shadow: 2px 2px 4px 0 rgba(26,46,53,0.04), -2px -2px 1px 0 #FFFFFF, -4px -4px 30px 0 rgba(255,255,255,0.88), 4px 4px 30px 0 rgba(26,46,53,0.24);
      //			margin-top: -12px;
    }
    span {
      display: inline-block;
    }
  }
  &__title {
    color: $color-text-therciary;
    font-family: $font-family-roboto-medium;
    font-size: 20px;
    // font-weight: 500;
    letter-spacing: 1px;
    line-height: 24px;
    text-decoration: none;
  }
  &__image {
    height: 64px;
    width: 64px;
    margin-right: 20px;
    background-repeat: no-repeat;
    &--conv-help {
      background-image: url(/sites/sede/themes/drpl_sede/images/global/ic_pc-show.svg);
    }
    &--licita {
      background-image: url(/sites/sede/themes/drpl_sede/images/global/ic_laptop-letter.svg);
    }
    &--tramite {
      background-image: url(/sites/sede/themes/drpl_sede/images/global/ic_paper-pencil.svg);
    }
    &--notification {
      // content: url('#{$wf__image-path-images}/global/ic_pc-pensil.svg');
      background-image: url(/sites/sede/themes/drpl_sede/images/global/ic_pc-pencil.svg);
    }
  }
}

.field--item {
  .hover-wrapper {
    line-height: 64px;
    &:hover a {
      box-shadow: 4px 4px 15px 0 rgba(26, 46, 53, 0.24);
      transform: translate(-2px, -2px);
    }
  }
}

@media only screen and (max-width: 992px) {
  .card-item-link {
    &__image {
      height: 58px;
      min-width: 66px;
      margin-right: 14px;
    }
    &__content {
      // padding: 10px;
      // width: 330px;
    }
    &__title {
      font-size: 18px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .card-item-link {
    &__content {
      // width: 100%;
    }
  }
body{
  .other-process__detail-content{
    .field--item_procedimientos{
      .hover-wrapper{
        width: 100%;
        max-width: 380px;
      }
    }

  }}
}

@media only screen and (max-width: 768px) {
  .card-item-link {
    &__content {
      margin-right: 0;
      &:hover,
      &:active,
      &:focus {
        // height: 100%;
        // width: 100%;
        // transition: 0.25s;
        // margin-top: -6px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .card-item-link {
    &__container {
      min-height: 420px;
    }
  }
}

.other-process__detail-content {
  .field--item{
    margin-left: 0;
  }
  .field--item_procedimientos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .hover-wrapper {
      line-height: 64px;
      width: 50%;
      padding: 16px;
      border-radius: 4px;
      transition: 0.25s;
      display: flex;
      align-items: center;

      &:hover {
        box-shadow: 4px 4px 15px 0 rgba(26, 46, 53, 0.24);
        transform: translate(-2px, -2px);
      }

      a {
        text-decoration: none;
        color: #8b9496;
        font-family: "Roboto Medium", sans-serif;
        font-size: 20px;
        line-height: 24px;

        &:hover {
          box-shadow: none;
          transform: none;
        }
      }
    }
  }
}
